<template>
  <div class="app-base-layout app-height">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "BaseLayout",
};
</script>
