<template>
  <div class="login app-absolute-layout">

    <div class="container-fluid app-height">

      <div class="app-flex-layout flex-center-center">

        <div class="login-card d-flex">
          <div class="login-greeting w-50">

            <div class="logo img"><img :src="logo" alt=""/></div>
            <div class="border-bottom border-blue400 my-24"></div>
            <h3 class="text-primary fw-bold mb-12">Welcome to Janasachetan Saccos</h3>
            <p>Manage your content by logging in to the admin portal</p>
          </div>

          <div class="login-form w-50">
            <h3 class="mb-24">Sign In</h3>
            <h4 v-if="networkError" class="text-error text-danger">
              {{ networkError }}
            </h4>
            <form action="">
              <div class="mb-16">
                <label htmlfor="">Email Address</label>
                <input class="form-control" type="email" placeholder="Please Enter Your Email." v-model="user.email"/>
                <VueErrors :errors=this.errors error_name="email"/>
              </div>
              <div class="mb-16">
                <label htmlfor="">Password</label>
                <div class="form-icon trail">
                  <input class="form-control" :type="passwordFieldType" placeholder="Please Enter Your Password."
                         v-model="user.password"/>
                  <i class="ic-view" @mousedown="showPassword" @mouseup="showPassword"></i>
                </div>
                <vueErrors :errors=this.errors error_name="password"/>
              </div>
              <a href="#" class="btn btn-secondary btn-block mt-24" @click.prevent="login">Login</a>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import loginBck from "../../assets/img/login-bck.png";
import logo from "../../assets/img/janasachetan-logo.png";
import Auth from '@/Auth.js';
import VueErrors from '@/helper/errors'

export default {
  name: "Login",
  components: {VueErrors},
  data() {
    return {
      loginBck,
      errors: '',
      networkError: '',
      logo,
      passwordFieldType: 'password',
      user: {
        email: '',
        password: '',
      },

    };
  },
  methods: {
    login() {
      this.axios.post('login', this.user)
  
          .then(({data}) => {
            window.localStorage.setItem('newtoken', data.access_token);
            Auth.login(data.access_token, data.user); //set local storage
            this.$router.push('/');
            window.location.reload();
          })
          .catch((error) => {
            this.handleCatch(error)
          })
      ;
    },
    handleCatch: function (error) {
      this.resetForm()
      if (error.code === 'ERR_NETWORK') {
        this.networkError = error.message;
      } else if (error.response.data.status === 401) {
        this.$toast.error(error.response.data.error);
      } else {
        this.errors = error.response.data.errors;
      }
    },

    showPassword() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },

    resetForm() {
      this.networkError = '';
      this.errors = ''
    }
  }
}
</script>
