<template>
  <div class="sidebar-01" :class="{ toggled: isToggled }">
    <div class="position-relative h-100">
      <absolute-layout :scrollable="true">
        <ul class="sidebar-01-list">
          <li
              v-for="data in menuList"
              :key="data.index"
              :class="{ hasSub: data.hasSub }"
          >
            <template v-if="data.hasSub">
              <a
                  type="button"
                  data-bs-toggle="collapse"
                  :data-bs-target="'#multiCollapseExample2' + data.idName"
                  aria-expanded="false"
                  :aria-controls="'multiCollapseExample2'+ data.idName"
              >
                {{ data.title }}
              </a>
              <div class="collapse multi-collapse" :id="'multiCollapseExample2' + data.idName">
                <div class="card">
                  <router-link
                      v-for="item in data.subMenu"
                      :key="item.index"
                      :to="item.link"
                  >
                    {{ item.innerTitle }}
                  </router-link>
                </div>
              </div>
            </template>
            <template v-else>
              <router-link :to="data.link"> {{ data.title }}</router-link>
            </template>
          </li>
        </ul>
      </absolute-layout>
    </div>
  </div>
</template>

<script>
// import FlexLayout from "../Layout/FlexLayout.vue";
import AbsoluteLayout from "../Layout/AbsoluteLayout.vue";

export default {
  name: "AppSidebar",
  components: {AbsoluteLayout},
  props: {isToggled: Boolean},
  data() {
    return {
      menuList: [
        {
          title: "Dashboard",
          link: "/",
          hasSub: false,
        },
        {
          title: "Company & Branch",
          idName: "company_and_branch",
          hasSub: true,
          subMenu: [
            {
              innerTitle: "Company Setting",
              link: "/company-settings",
            },
            {
              innerTitle: "Branch Setting",
              link: "/branches",
            },
          ],
        },
        {
          title: "Loan Type",
          link: "/loan-types",
          hasSub: false,
        },
        {
          title: "Loan Application",
          link: "/loan-application",
          hasSub: false,
        },
        {
          title: "Membership Application",
          link: "/membership-applications",
          hasSub: false,
        },
        {
          title: "Menu",
          idName: "menu",
          hasSub: true,
          subMenu: [
            {
              innerTitle: "Menu Level One",
              link: "/menu-level-one",
            },
            {
              innerTitle: "Menu Level Two",
              link: "/menu-level-two",
            },
            {
              innerTitle: "Menu Level Three",
              link: "/menu-level-three",
            },
          ],
        },
        {
          title: "Sliders & Popups",
          idName: "sliders_popups",
          hasSub: true,
          subMenu: [
            {
              innerTitle: "Sliders",
              link: "/sliders",
            },
            {
              innerTitle: "Popups",
              link: "/popups",
            },
          ],
        },

        {
          title: "Posts",
          link: "/posts",
          hasSub: false,
        },
        {
          title: "Members",
          idName: "member",
          hasSub: true,
          subMenu: [
            {
              innerTitle: "BOD",
              link: "/bod",
            },
            {
              innerTitle: "Management",
              link: "/management",
            },
            {
              innerTitle: "Supervisory",
              link: "/supervisory",
            },
          ],
        },
        {
          title: "Services",
          idName: "services",
          hasSub: true,
          subMenu: [
            {
              innerTitle: "Service Level One",
              link: "/service-level-one",
            },
            {
              innerTitle: "Service Level Two",
              link: "/service-level-two",
            },
            {
              innerTitle: "Service Level Three",
              link: "/service-level-three",
            },
          ],
        },
        {
          title: "Gallery",
          idName: "gallery",
          hasSub: true,
          subMenu: [
            {
              innerTitle: "Gallery Category",
              link: "/gallery-categories",
            },
            {
              innerTitle: "Gallery",
              link: "/galleries",
            },
          ],
        },

        {
          title: "Faq",
          link: "/faqs",
          hasSub: false,
        },
        {
          title: "Document",
          link: "/documents",
          hasSub: false,
        },
        {
          title: "Sitepage",
          link: "/site-pages",
          hasSub: false,
        },

        {
          title: "Properties",
          link: "/properties",
          hasSub: false,
        },
        {
          title: "Footer",
          link: "/footers",
          hasSub: false,
        },
        {
          title: "Social Links",
          link: "/social-links",
          hasSub: false,
        },
        {
          title: "Sidebar Icons",
          link: "/sidebar-icons",
          hasSub: false,
        },
        {
          title: "Audio Videos",
          link: "/audio-videos",
          hasSub: false,
        },
        {
          title: "Career & Applications",
          hasSub: true,
          subMenu: [
            {
              innerTitle: "Career Listing",
              link: "/career-listings",
            },
            {
              innerTitle: "Career Application",
              link: "/career-applications",
            },
          ],
        },

        {
          title: "Contact Message",
          link: "/contact-form-message",
          hasSub: false,
        },

        /*

                {
                  title: "Main-Dashboard",
                  link: "/main-dashboard",
                  hasSub: false,
                },
                {
                  title: "Complaints",
                  link: "/complaint",
                  hasSub: false,
                },
                {
                  title: "Role Management",

                  hasSub: true,
                  subMenu: [
                    {
                      innerTitle: "Role Setup",
                      link: "/role-setup",
                    },
                    {
                      innerTitle: "Role Edit",
                      link: "/role-edit",
                    },
                  ],
                },
                {
                  title: "Master Configuration",
                  link: "/master-config",
                  hasSub: false,
                },
                {
                  title: "Form Elements",
                  link: "/form-element",
                  hasSub: false,
                },
                {
                  title: "Request Dashboard",
                  link: "/requests",
                  hasSub: false,
                },
        */

      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.sidebar-01-list > li.hasSub .card .active {
  color: rgb(300, 300, 300);
  font-weight: 600;
}
</style>
