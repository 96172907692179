<template>
  <div class="app-flex-layout">
    <div class="page-header">
      <h5>Membership Applications</h5>
      <a class="btn btn-sm btn-outline-primary" href="#" @click.prevent="exportApplication">Export Membership
        Applications</a>
    </div>
    <!--    OffCanvas Starts-->
    <div
        class="offcanvas offcanvas-end offcanvas-01"
        id="offcanvasRight"
        tabindex="-1"
        aria-labelledby="offcanvasRightLabel"
    >
      <div class="offcanvas-header">
        <h5 class="text-dark" id="offcanvasRightLabel">Applicants Details View</h5>
        <a href="#" class="btn btn-secondary btn-download" @click="print()">Print Application</a>
        <button
            class="btn btn-danger btn-sm"
            type="button"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
        >
          <i class="ic-close"></i>
        </button>
      </div>
      <div class="offcanvas-body" id="printableArea">
        <img src="" style="margin-bottom: 24px" alt=""/>
        <h1>Membership Form</h1>

        <table width=" 100%">
          <tr>
            <td>
              Respected Chairman, <br/> Janasachetan Savings and Credit Cooperative Ltd. ,
              <br/>Baiteshowr-5, Dolakha
            </td>
            <td align="right">
              <div style="height: 120px; width: 100px; border: 1px solid black">
                <img style="height: 120px; width: 100px;"
                     :src="viewedApplication.applicant_image_path ? viewedApplication.applicant_image_path : ''"
                     alt=""/>
              </div>
            </td>
          </tr>
        </table>

        <h3 style="text-align: center">Topic: Membership Application</h3>

        <p style="margin-bottom: 24px">Respected Sir/Maa'm,</p>
        <p>
          I request you for membership in the cooperative with the membership charge of Rs.
          <input type="text" class="input-default" :placeholder="viewedApplication.membership_charge" readonly/> and
          share of
          <input type="text" class="input-default" :placeholder="viewedApplication.share_quantity" readonly/>
          stock at the rate of Rs.100 that
          amounts to Rs.<input type="text" class="input-default" :placeholder="viewedApplication.share_cost" readonly/>,
          membership security fund as Rs.
          <input type="text" class="input-default" :placeholder="viewedApplication.membership_security_fund" readonly/>
          so, I would monthly deposit Rs.
          <input type="text" class="input-default" :placeholder="viewedApplication.monthly_deposit" readonly/>
          which totals to
          <input type="text" class="input-default" :placeholder="viewedApplication.total_deposit" readonly/>
          by agreeing with the Terms & Conditions of the cooperative.
        </p>

        <h3 style="margin-top: 32px; margin-bottom: 8px">Personal Information</h3>
        <p style="margin: 8px 0">Please enter your personal details</p>

        <table width="100%">
          <tr>
            <td>
              <label for="">Application's Name</label>
            </td>
          </tr>
          <tr>
            <td>
              <input type="text" class="input-default input-form" :placeholder="viewedApplication.applicants_name"
                     style="margin: 0; width: 100%" readonly/>
            </td>
          </tr>
        </table>
        <table width="100%" style="margin: 12px 0">
          <tr>
            <td>
              <label for="">Grandfather's Name</label>
            </td>
            <td>
              <label for="">Grandmother's Name</label>
            </td>
          </tr>
          <tr>
            <td>
              <input type="text" class="input-default input-form" :placeholder="viewedApplication.grandfathers_name"
                     style="margin: 0; width: 90%" readonly/>
            </td>
            <td>
              <input type="text" class="input-default input-form" :placeholder="viewedApplication.grandmothers_name"
                     style="margin: 0; width: 100%" readonly/>
            </td>
          </tr>
        </table>
        <table width="100%" style="margin: 12px 0">
          <tr>
            <td>
              <label for="">Father's Name</label>
            </td>
            <td>
              <label for="">Mother's Name</label>
            </td>
          </tr>
          <tr>
            <td>
              <input type="text" class="input-default input-form" :placeholder="viewedApplication.fathers_name"
                     style="margin: 0; width: 90%" readonly/>
            </td>
            <td>
              <input type="text" class="input-default input-form" :placeholder="viewedApplication.mothers_name"
                     style="margin: 0; width: 100%" readonly/>
            </td>
          </tr>
        </table>

        <!-- permanent address -->
        <h4 style="margin-top: 32px; margin-bottom: 8px">Permanent Address</h4>

        <table width="100%" style="margin: 12px 0">
          <tr>
            <td>
              <label for="">Province</label>
            </td>
            <td>
              <label for="">District</label>
            </td>
          </tr>
          <tr>
            <td>
              <input type="text" class="input-default input-form" :placeholder="viewedApplication.permanent_province"
                     style="margin: 0; width: 90%" readonly/>
            </td>
            <td>
              <input type="text" class="input-default input-form" :placeholder="viewedApplication.permanent_district"
                     style="margin: 0; width: 100%" readonly/>
            </td>
          </tr>
        </table>
        <table width="100%" style="margin: 12px 0">
          <tr>
            <td>
              <label for="">V.D.C/Municipality/Metropolitan</label>
            </td>
            <td>
              <label for="">Ward No.</label>
            </td>
          </tr>
          <tr>
            <td>
              <input type="text" class="input-default input-form"
                     :placeholder="viewedApplication.permanent_municipality"
                     style="margin: 0; width: 90%" readonly/>
            </td>
            <td>
              <input type="text" class="input-default input-form" :placeholder="viewedApplication.permanent_ward"
                     style="margin: 0; width: 100%" readonly/>
            </td>
          </tr>
        </table>
        <table width="50%" style="margin: 12px 0">
          <tr>
            <td>
              <label for="">Tole</label>
            </td>
          </tr>
          <tr>
            <td>
              <input type="text" class="input-default input-form" :placeholder="viewedApplication.permanent_tole"
                     style="margin: 0; width: 100%" readonly/>
            </td>
          </tr>
        </table>

        <!-- Temporary address -->
        <h4 style="margin-top: 32px; margin-bottom: 8px">Temporary Address</h4>
        <table width="100%" style="margin: 12px 0">
          <tr>
            <td>
              <label for="">Province</label>
            </td>
            <td>
              <label for="">District</label>
            </td>
          </tr>
          <tr>
            <td>
              <input type="text" class="input-default input-form" :placeholder="viewedApplication.temporary_province"
                     style="margin: 0; width: 90%" readonly/>
            </td>
            <td>
              <input type="text" class="input-default input-form" :placeholder="viewedApplication.temporary_district"
                     style="margin: 0; width: 100%" readonly/>
            </td>
          </tr>
        </table>
        <table width="100%" style="margin: 12px 0">
          <tr>
            <td>
              <label for="">V.D.C/Municipality/Metropolitan</label>
            </td>
            <td>
              <label for="">Ward No.</label>
            </td>
          </tr>
          <tr>
            <td>
              <input type="text" class="input-default input-form"
                     :placeholder="viewedApplication.temporary_municipality"
                     style="margin: 0; width: 90%" readonly/>
            </td>
            <td>
              <input type="text" class="input-default input-form" :placeholder="viewedApplication.temporary_ward"
                     style="margin: 0; width: 100%" readonly/>
            </td>
          </tr>
        </table>
        <table width="50%" style="margin: 12px 0">
          <tr>
            <td>
              <label for="">Tole</label>
            </td>
          </tr>
          <tr>
            <td>
              <input type="text" class="input-default input-form" :placeholder="viewedApplication.temporary_tole"
                     style="margin: 0; width: 100%" readonly/>
            </td>
          </tr>
        </table>

        <!-- Current address -->
        <h4 style="margin-top: 32px; margin-bottom: 8px">Current Address</h4>
        <table width="100%" style="margin: 12px 0">
          <tr>
            <td>
              <label for="">Province</label>
            </td>
            <td>
              <label for="">District</label>
            </td>
          </tr>
          <tr>
            <td>
              <input type="text" class="input-default input-form" :placeholder="viewedApplication.current_province"
                     style="margin: 0; width: 90%" readonly/>
            </td>
            <td>
              <input type="text" class="input-default input-form" :placeholder="viewedApplication.current_district"
                     style="margin: 0; width: 100%" readonly/>
            </td>
          </tr>
        </table>
        <table width="100%" style="margin: 12px 0">
          <tr>
            <td>
              <label for="">V.D.C/Municipality/Metropolitan</label>
            </td>
            <td>
              <label for="">Ward No.</label>
            </td>
          </tr>
          <tr>
            <td>
              <input type="text" class="input-default input-form" :placeholder="viewedApplication.current_municipality"
                     style="margin: 0; width: 90%" readonly/>
            </td>
            <td>
              <input type="text" class="input-default input-form" :placeholder="viewedApplication.current_ward"
                     style="margin: 0; width: 100%" readonly/>
            </td>
          </tr>
        </table>
        <table width="50%" style="margin: 12px 0">
          <tr>
            <td>
              <label for="">Tole</label>
            </td>
          </tr>
          <tr>
            <td>
              <input type="text" class="input-default input-form" :placeholder="viewedApplication.current_tole"
                     style="margin: 0; width: 100%" readonly/>
            </td>
          </tr>
        </table>

        <h4 style="margin-top: 32px; margin-bottom: 8px">Personal Details</h4>

        <table width="100%" style="border-spacing: 0 12px">
          <tr>
            <td width="50%">
              Date of Birth(B.S)
              <input type="text" style="display: block; width: 90%" class="input-default input-form"
                     :placeholder="viewedApplication.date_of_birth" readonly/>
            </td>
            <td>
              Gender
              <form action="">
                <label for="html" style="font-weight: 400; font-size: 14px"><b>
                  {{ viewedApplication.gender }}</b></label>
              </form>
            </td>
          </tr>
          <tr>
            <td width="50%">
              Caste
              <input
                  type="text"
                  style="display: block; width: 90%"
                  class="input-default input-form"
                  :placeholder="viewedApplication.caste"
                  readonly
              />
            </td>
            <td width="50%">
              Religion
              <input
                  type="text"
                  style="display: block; width: 100%"
                  class="input-default input-form"
                  :placeholder="viewedApplication.religion"
                  readonly
              />
            </td>
          </tr>
          <tr>
            <td width="50%">
              Phone No.
              <input
                  type="text"
                  style="display: block; width: 90%"
                  class="input-default input-form"
                  :placeholder="viewedApplication.phone_no"
                  readonly
              />
            </td>
            <td width="50%">
              Email Address
              <input
                  type="text"
                  style="display: block; width: 100%"
                  class="input-default input-form"
                  :placeholder="viewedApplication.email"
                  readonly
              />
            </td>
          </tr>

          <tr>
            <td width="50%">
              Qualification
              <input
                  type="text"
                  style="display: block; width: 90%"
                  class="input-default input-form"
                  :placeholder="viewedApplication.qualification"
                  readonly
              />
            </td>
            <td width="50%">
              Occupation
              <input
                  type="text"
                  style="display: block; width: 100%"
                  class="input-default input-form"
                  :placeholder="viewedApplication.occupation"
                  readonly
              />
            </td>
          </tr>
          <tr>
            <td width="50%">
              Marital Status
              <input
                  type="text"
                  style="display: block; width: 90%"
                  class="input-default input-form"
                  :placeholder="viewedApplication.marital_status"
                  readonly
              />
            </td>
            <td width="50%">
              Spouse Name
              <input
                  type="text"
                  style="display: block; width: 100%"
                  class="input-default input-form"
                  :placeholder="viewedApplication.spouse_name ? viewedApplication.spouse_name : ''"
                  readonly
              />
            </td>
          </tr>
        </table>
        <p>
          If you are the member of any other SACOOS, please enter the name and
          address of the SACOOS
        </p>

        <table width="100%" style="margin: 12px 0">
          <tr>
            <td width="50%">
              <label for="">SAACOS's </label>
              <input type="text" class="input-default input-form"
                     :placeholder="viewedApplication.other_saccos_name ? viewedApplication.other_saccos_name : ''"
                     style="margin: 0; width: 90%" readonly/>
            </td>
            <td width="50%">
              <label for="">SAACOS's Address</label>
              <input type="text" class="input-default input-form"
                     :placeholder="viewedApplication.other_saccos_address ? viewedApplication.other_saccos_address : ''"
                     style="margin: 0; width: 100%" readonly/>
            </td>
          </tr>
        </table>
        <p>Please provide the documents asked below to verify your identity</p>
        <table width="100%" style="margin: 12px 0; border-spacing: 0 12px">
          <tr>
            <td width="50%">
              <label for="">Type of Identification Document </label>
              <input type="text" class="input-default input-form"
                     :placeholder="viewedApplication.identification_document_type"
                     style="margin: 0; width: 90%" readonly/>
            </td>
            <td width="50%">
              <label for="">Identification Document Number</label>
              <input type="text" class="input-default input-form"
                     :placeholder="viewedApplication.identification_document_number"
                     style="margin: 0; width: 100%" readonly/>
            </td>
          </tr>
          <tr>
            <td width="50%">
              <label for="">Issued Place </label>
              <input type="text" class="input-default input-form"
                     :placeholder="viewedApplication.identification_document_issue_place"
                     style="margin: 0; width: 90%" readonly/>
            </td>
            <td width="50%">
              <label for="">Issued Date</label>
              <input type="text" class="input-default input-form"
                     :placeholder="viewedApplication.identification_document_issue_date"
                     style="margin: 0; width: 100%"/>
            </td>
          </tr>
        </table>

        <h4 style="margin-top: 32px; margin-bottom: 8px">Family Information</h4>
        <table width="100%" style="margin: 12px 0; border-spacing: 0 12px" v-if="viewedApplication.family_info">
          <tr>
            <th>SN.</th>
            <th>Name</th>
            <th>Age</th>
            <th>Occupation</th>
            <th>Remarks</th>
          </tr>
          <tr v-for="(item,index) in JSON.parse(viewedApplication.family_info)" :key="index">
            <td>{{ ++index }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.age }}</td>
            <td>{{ item.occupation }}</td>
            <td>{{ item.remarks ? item.remarks : "-" }}</td>
          </tr>
        </table>

        <h4 style="margin-top: 32px; margin-bottom: 8px">Financial/Income Source</h4>

        <table style="margin: 8px 0; border-spacing: 0 12px" v-if="viewedApplication.income">
          <tr>
            <th>SN.</th>
            <th>Source</th>
            <th>Amount</th>
          </tr>
          <tr v-for="(item,index) in JSON.parse(viewedApplication.income)" :key="index">
            <td>{{ ++index }}</td>
            <td>{{ item.source }}</td>
            <td>{{ item.amount }}</td>
          </tr>
        </table>

        <h4 style="margin-top: 32px; margin-bottom: 8px">Nominee Details</h4>
        <p>Please enter nominee details</p>
        <table width="100%" style="margin: 12px 0; border-spacing: 0 12px">
          <tr>
            <td>
              <label for="">Name </label>
              <input type="text" class="input-default input-form" :placeholder="viewedApplication.nominee_name"
                     style="margin: 0; width: 90%" readonly/>
            </td>
            <td>
              <label for="">Relation</label>
              <input type="text" class="input-default input-form" :placeholder="viewedApplication.nominee_relation"
                     style="margin: 0; width: 90%" readonly/>
            </td>
            <td>
              <label for="">Age</label>
              <input type="text" class="input-default input-form" :placeholder="viewedApplication.nominee_age"
                     style="margin: 0; width: 90%" readonly/>
            </td>
          </tr>
        </table>
        <table width="100%">
          <tr>
            <td>
              <label for="">Identification No (Citizenship No) </label>
              <input type="text" class="input-default input-form"
                     :placeholder="viewedApplication.nominee_identification_no"
                     style="margin: 0; width: 90%" readonly/>
            </td>
            <td>
              <label for="">Address</label>
              <input type="text" class="input-default input-form" :placeholder="viewedApplication.nominee_address"
                     style="margin: 0; width: 100%" readonly/>
            </td>
          </tr>
        </table>
        <table style="margin: 12px 0" width="100%">
          <tr>
            <td>
              <p>Nominee's Photo</p>
              <div style="height: 120px; width: 120px; border: 1px solid black">
                <img :src="viewedApplication.nominee_photo ? viewedApplication.nominee_photo_path : ''"
                     style="height: 120px; width: 120px;" alt="">
              </div>
            </td>
            <td>
              <p>Nominee's Signature</p>
              <div style="height: 120px; width: 120px; border: 1px solid black">
                <img :src="viewedApplication.nominee_signature ? viewedApplication.nominee_signature_path : ''"
                     style="height: 120px; width: 120px;" alt="">
              </div>
            </td>
            <td>
              <p>Applicant's signature</p>
              <div style="height: 120px; width: 120px; border: 1px solid black">
                <img :src="viewedApplication.applicant_image ? viewedApplication.applicant_image_path : ''"
                     style="height: 120px; width: 120px;" alt="">
              </div>
            </td>
          </tr>
        </table>

        <h4 style="margin-top: 32px; margin-bottom: 8px">Witness Information</h4>
        <p>Please enter Witness Information</p>

        <p>I know the witness closely.</p>
        <table width="100%" style="border-spacing: 0 12px">
          <tr>
            <td>
              <label for="">Name </label>
              <input type="text" class="input-default input-form" :placeholder="viewedApplication.witness_name"
                     style="margin: 0; width: 90%" readonly/>
            </td>
            <td>
              <label for="">Membership No.</label>
              <input type="text" class="input-default input-form" :placeholder="viewedApplication.witness_membership_no"
                     style="margin: 0; width: 100%" readonly/>
            </td>
          </tr>
          <tr>
            <td>
              <p>Witness's signature</p>
              <div style="height: 120px; width: 120px; border: 1px solid black">
                <img :src="viewedApplication.witness_signature ? viewedApplication.witness_signature_path : ''"
                     style="height: 120px; width: 120px;" alt="">
              </div>
            </td>
          </tr>
        </table>

      </div>

    </div>
    <!--    OffCanvas Ends-->

    <div class="pt-24 px-24">
    </div>
    <template>
      <div class="app-flex-layout p-24">
        <div class="d-flex mb-16">
          <div class="dropdown dropdown-01">
          </div>
        </div>
        <div class="position-relative h-100">
          <div class="app-height app-absolute-layout scrollable table-responsive">
            <table class="table table-01">
              <thead>
              <th v-for="item in tableHeading" :key="item.index">{{ item }}</th>
              </thead>
              <tbody>

              <tr v-if="data && data.length === 0">No Data Found</tr>
              <tr v-for="(item, index) in data" :key="index">

                <td>{{ ++index }}</td>
                <td>{{ item.applicants_name }}</td>
                <td>{{ item.date_of_birth }}</td>
                <td>{{ item.gender }}</td>
                <td>{{ item.phone_no }}</td>
                <td><a :href=" item ? item.applicant_image_path : ''" target="_blank"
                       class="text-white btn btn-sm btn-primary rounded-pill">
                  View Applicants Photo</a>
                </td>
                <td><a :href=" item ? item.applicant_signature_path : ''" target="_blank"
                       class="text-white btn btn-sm btn-primary rounded-pill">
                  View Applicants Signature</a>
                </td>
                <td>
                  <button
                      title="View Applicants Details"
                      class="btn btn-sm btn-gray"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasRight"
                      aria-controls="offcanvasRight"
                      @click="viewItem(item.id, index)"
                  >
                    <i class="ic-view"></i>
                  </button>
                </td>

              </tr>
              <template v-if="isLoading">
                <Loading/>
              </template>
              </tbody>
            </table>
            <div class="table-footer">
              <paginate
                  :page-class="'page-item'"
                  :page-count="page"
                  :page-link-class="'page-link'"
                  :container-class="'pagination  pagination-sm'"
                  :prev-text="`<i class='ic-chevron-left'></i>`"
                  :next-text="` <i class='ic-chevron-right'></i>`"
                  :prev-class="'page-link pagination-arrow mr-2'"
                  :next-class="'page-link pagination-arrow ml-2'"
                  :click-handler="handlePagination"
              ></paginate>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>


<script>
import Loading from "../../components/Loading";
import Paginate from "vuejs-paginate";
import ApiResource from "../../plugins/api";

let apiResource = new ApiResource("membership-applications");


export default {
  name: "MembershipApplication",
  components: {Loading, Paginate},
  data() {
    return {
      name: '',
      order: '',
      status: '',
      type: '',
      image: null,
      edit_id: null,
      isLoading: false,
      errors: [],
      tableHeading: ['SN.', 'Applicant Name', 'DOB', 'Gender', 'Phone No', 'Applicants Photo', 'Applicants Signature', 'Action'],
      data: [],
      page: 0,
      endPoint: 'career-applications',
      viewedApplication: {},


    };
  },
  methods: {

    print() {
      let divContents = document.getElementById("printableArea").innerHTML;
      let a = window.open();
      a.document.write(divContents);
      a.document.close();
      a.print();

    },

    async fetchData() {
      this.data = [];
      this.isLoading = true
      try {
        let {data} = await apiResource.list();
        this.data = data.membership_applications.data;

        let total = data.membership_applications.total;
        let per_page = data.membership_applications.per_page;
        this.page = Math.ceil(total / per_page);
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },

    async handlePagination(pageNum) {
      try {
        this.isLoading = true
        this.paramQuery.page = pageNum;
        let {data} = await apiResource.list(this.paramQuery);
        this.data = data.membership_applications.data;
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },

    async exportApplication() {

      apiResource.exportFile('export')
          .then((responseData) => {
            const fileURL = window.URL.createObjectURL(new Blob([responseData]));
            const fileLink = document.createElement('a');
            // console.log(fileLink)

            fileLink.href = fileURL;
            fileLink.setAttribute('download', 'MembershipApplications.csv');
            document.body.appendChild(fileLink);

            fileLink.click();
          });
    },

    viewItem(id) {
      let viewedApplication = this.data.find(data => data.id == id)
      this.viewedApplication = viewedApplication;
    }


  },
  mounted() {
    this.fetchData();
  }
};
</script>

<style scoped>
html,
body {
  font-family: "inter";
  font-weight: 400;
  width: 595pt;
  height: 842pt;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  line-height: 32px;
  margin: 0;
}

input {
  padding: 4px 8px;
  border-radius: 4px;
  margin: 0 8px;
}

.input-form {
  padding: 8px 8px;
  margin: 0;
}

input::placeholder {
  font-size: 12px;
}

/* css for the input with only border-bottom */

.input-line {
  border: unset;
  border-bottom: 1px solid black;
}

.input-dotted {
  border: unset;
  border-bottom: 1px dotted black;
}

.input-none {
  border: unset;
}

.input-default {
  border: 1px solid #c4c4c4;
}

/* css for the default table */
/* start */

.table-default {
  border: 1px solid black;
  border-collapse: collapse;
}

.table-default th {
  border: 1px solid black;
  padding: 3px;
}

.table-default td {
  border: 1px solid black;
  height: 30px;
}

/* end  */
</style>