<template>
  <div class="app-flex-layout">
    <div class="page-header">
      <h5>Social Link</h5>

      <button
          class="btn btn-sm btn-outline-primary"
          title="view"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight"
          @click="addButtonEvent"
      >
        Add Social Link
      </button>
    </div>

    <!--    OffCanvas Starts-->
    <div
        class="offcanvas offcanvas-end offcanvas-01"
        id="offcanvasRight"
        tabindex="-1"
        aria-labelledby="offcanvasRightLabel"
    >
      <div class="offcanvas-header">
        <h5 class="text-dark" id="offcanvasRightLabel">
          {{ this.edit_id ? 'Edit SocialLink' : 'Add New SocialLink' }}</h5>
        <button
            class="btn btn-danger btn-sm"
            type="button"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
        >
          <i class="ic-close"></i>
        </button>
      </div>
      <div class="offcanvas-body">

        <form class="row mt-24" method="POST" @submit.prevent="saveOrUpdateData">

          <div class="col-md-12 mb-16">
            <label>Name</label>
            <input type="text" v-model="name" class="form-control" placeholder="Name (Eg. Facebook)"/>
            <VueErrors :errors=this.errors error_name="name"/>
          </div>

          <div class="col-md-12 mb-16">
            <label>Icon</label>
            <input type="text" v-model="icon" class="form-control" placeholder="Icon (Eg. ic-facebook)"/>
            <VueErrors :errors=this.errors error_name="icon"/>
          </div>

          <div class="col-md-12 mb-16">
            <label>Link</label>
            <input type="text" v-model="link" class="form-control" placeholder="Place Link Here (Eg. https://)"/>
            <VueErrors :errors=this.errors error_name="link"/>
          </div>

          <div class="col-md-12 mb-16">
            <label>Order</label>
            <input type="number" v-model="order" class="form-control" placeholder="Order (Eg. 1)"/>
            <VueErrors :errors=this.errors error_name="order"/>
          </div>

          <div class="col-md-16 mb-16">
            <label for="">Status</label>
            <select v-model="status" class="form-select">
              <option value="">Select Status..</option>
              <option value="1">Active</option>
              <option value="0">Inactive</option>
            </select>
            <VueErrors :errors=this.errors error_name="status"/>
          </div>

          <div class="offcanvas-footer">
            <button type="submit"
                    class="btn btn-green mr-16">
              <i class="ic-checkmark mr-8"></i>
              {{ this.edit_id ? 'Update' : 'Save' }}
            </button>
            <button class="btn btn-red" data-bs-dismiss="offcanvas" aria-label="Close"><i class="ic-close mr-8"></i>
              Cancel
            </button>
          </div>

        </form>
      </div>

    </div>
    <!--    OffCanvas Ends-->


    <div class="pt-24 px-24">
    </div>
    <template>
      <div class="app-flex-layout p-24">
        <div class="d-flex mb-16">
          <div class="dropdown dropdown-01">
          </div>
        </div>
        <div class="position-relative h-100">
          <div class="app-height app-absolute-layout scrollable table-responsive">
            <table class="table table-01">
              <thead>
              <th v-for="item in tableHeading" :key="item.index">{{ item }}</th>
              </thead>
              <tbody>

              <tr v-if="data && data.length === 0">No Data Found</tr>
              <tr v-for="(item, index) in data" :key="index">
                <td>{{ ++index }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.icon }}</td>
                <td>{{ item.link }}</td>
                <td>{{ item.order }}</td>
                <td>
                  <div v-if="item.status == 0" class="badge bg-red rounded-pill">Inactive</div>
                  <div v-else-if="item.status == 1" class="badge bg-green rounded-pill">Active</div>
                </td>
                <td>
                  <button
                      title="Edit"
                      class="btn btn-sm btn-gray"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasRight"
                      aria-controls="offcanvasRight"
                      @click="editItem(item.id, index)"
                  >
                    <i class="ic-edit"></i>
                  </button>
                  <button
                      title="Delete"
                      class="btn btn-sm btn-gray"
                      @click="deleteItem(item.id, index)"
                  >
                    <i class="ic-delete"></i>
                  </button>
                </td>
              </tr>
              <template v-if="isLoading">
                <Loading/>
              </template>
              </tbody>
            </table>
            <div class="table-footer">
              <paginate
                  :page-class="'page-item'"
                  :page-count="page"
                  :page-link-class="'page-link'"
                  :container-class="'pagination  pagination-sm'"
                  :prev-text="`<i class='ic-chevron-left'></i>`"
                  :next-text="` <i class='ic-chevron-right'></i>`"
                  :prev-class="'page-link pagination-arrow mr-2'"
                  :next-class="'page-link pagination-arrow ml-2'"
                  :click-handler="handlePagination"
              ></paginate>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>


<script>
import VueErrors from '@/helper/errors'
import Loading from "../../components/Loading";
import Paginate from "vuejs-paginate";
import ApiResource from "../../plugins/api";
let apiResource = new ApiResource("social-links");


export default {
  name: "SocialLink",
  components: {VueErrors, Loading, Paginate},
  data() {

    return {
      name: '',
      link: '',
      icon: '',
      order: '',
      status: '',
      edit_id: null,
      isLoading: false,
      errors: [],
      tableHeading: ['SN.', 'Name', 'Icon', 'Link', 'Order', 'Status', 'Action'],
      data: [],
      page: 0,
      endPoint: 'social-links',

    };
  },
  methods: {
    addButtonEvent() {
      this.formReset();
    },

    async fetchData() {
      this.data = [];
      this.isLoading = true
      try {
        let {data} = await apiResource.list();
        this.data = data.social_links.data;

        let total = data.social_links.total;
        let per_page = data.social_links.per_page;
        this.page = Math.ceil(total / per_page);
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    async handlePagination(pageNum) {
      try {
        this.isLoading = true
        this.paramQuery.page = pageNum;
        let {data} = await apiResource.list(this.paramQuery);
        this.data = data.social_links.data;
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    storeData(storeUpdateFormData) {
      apiResource.store(storeUpdateFormData)
          .then((response) => {
            this.$toast.success(response.message);
            window.location.reload()
          })
          .catch((error) => {
            this.$toast.error("Please Check the Form. " + error.response.data.message);
            this.errors = error.response.data.errors;
          });
    },
    updateData(storeUpdateFormData) {
      storeUpdateFormData.append('_method', 'put');
      apiResource.update(this.edit_id, storeUpdateFormData)
          .then((response) => {
            this.$toast.success(response.message);
            window.location.reload()
          })
          .catch((error) => {
            this.$toast.error("Please Check the Form. " + error.response.data.message);
            this.errors = error.response.data.errors;
          });
    },
    deleteItem(id, index) {
      if (confirm("Are you sure you want to Delete?")) {
        apiResource.destroy(id)
            .then((response) => {
              this.$toast.success(response.message);
              this.fetchData();
              this.data.splice(index, 1)
            })
            .catch((error) => {
              console.log(error)
            });
      }
    },
    saveOrUpdateData() {
      let storeUpdateFormData = this.prepareFormData();
      let config = {
        header: {
          'Content-Type': 'multipart/form-data'
        }
      }
      if (this.edit_id > 0) {
        this.updateData(storeUpdateFormData, config);
      } else {
        this.storeData(storeUpdateFormData, config)
      }
    },

    prepareFormData() {
      let form_data = new FormData();
      form_data.append('name', this.name);
      form_data.append('icon', this.icon);
      form_data.append('link', this.link);
      form_data.append('order', this.order);
      form_data.append('status', this.status);

      return form_data;
    },

    editItem(id) {
      let data = this.data.find(data => data.id == id)
      if (data) {
        this.name = data.name
        this.icon = data.icon
        this.link = data.link
        this.order = data.order
        this.status = data.status
        this.edit_id = data.id
      }
    },
    changeImage(event) {
      this.image = event.target.files[0]
    },
    formReset() {
      this.name = '',
          this.icon = '',
          this.link = '',
          this.order = '',
          this.status = '',
          this.edit_id = null
    },
  },
  mounted() {
    this.fetchData();
  }
};
</script>