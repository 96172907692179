import Vue from "vue";
import Vuex from "vuex";
// import cardImg from "./assets/img/cardImg3.png";

Vue.use(Vuex);
export const store = new Vuex.Store({
  state: {
    selectedModulesArray: [],

    isHovered: false,
  },

  mutations: {},
});
