<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h3>
          Oops!
        </h3>
        <h3>
          404 Not Found</h3>
        <div class="error-details">
          Sorry, an error has occured, Requested page not found!
        </div>
        <button @click="$router.push('/')" class="btn btn-primary text-white">
          Go to Dashboard
        </button>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "PageNotFound"
}
</script>