<template>
  <div class="page-header">
    <h5>{{ heading }}</h5>

    <button v-if="action != 'null'" class="btn btn-sm btn-outline-primary">
      {{ action }}
    </button>
  </div>
</template>

<script>
  export default {
    name: "Filter",
    components: {},

    data() {
      return {};
    },
    props: {
      heading: String,
      action: {
        type: String,
        default: "null",
      },
    },
  };
</script>
