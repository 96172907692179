<template>
  <div class="app-flex-layout">
    <div class="page-header">
      <h5>Career Applications</h5>
    </div>



    <div class="pt-24 px-24">
    </div>
    <template>
      <div class="app-flex-layout p-24">
        <div class="d-flex mb-16">
          <div class="dropdown dropdown-01">
          </div>
        </div>
        <div class="position-relative h-100">
          <div class="app-height app-absolute-layout scrollable table-responsive">
            <table class="table table-01">
              <thead>
              <th v-for="item in tableHeading" :key="item.index">{{ item }}</th>
              </thead>
              <tbody>

              <tr v-if="data && data.length === 0">No Data Found</tr>
              <tr v-for="(item, index) in data" :key="index">

                <td>{{ ++index }}</td>
                <td>{{ item.career_id }}</td>
                <td>{{ item.career ? item.career.name : '' }}</td>
                <td>{{ item.first_name }}  {{item.last_name}}</td>
                <td>{{ item.email }}</td>
                <td>{{ item.phone_no}}</td>
                <td><a :href=" item ? item.cv_file_path : ''" target="_blank"
                       class="text-white btn btn-primary rounded-pill">
                  View CV</a>
                </td>
                <td><a :href=" item ? item.cover_letter_file_path : ''" target="_blank"
                       class="text-white btn btn-primary rounded-pill">
                  View Cover Letter</a>
                </td>
<!--                <td class="action">
                  <button
                      title="Delete"
                      class="btn btn-sm btn-gray"
                      @click="deleteItem(item.id, index)"
                  >
                    <i class="ic-delete"></i>
                  </button>
                </td>-->
              </tr>
              <template v-if="isLoading">
                <Loading/>
              </template>
              </tbody>
            </table>
            <div class="table-footer">
              <paginate
                  :page-class="'page-item'"
                  :page-count="page"
                  :page-link-class="'page-link'"
                  :container-class="'pagination  pagination-sm'"
                  :prev-text="`<i class='ic-chevron-left'></i>`"
                  :next-text="` <i class='ic-chevron-right'></i>`"
                  :prev-class="'page-link pagination-arrow mr-2'"
                  :next-class="'page-link pagination-arrow ml-2'"
                  :click-handler="handlePagination"
              ></paginate>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>


<script>
import Loading from "../../components/Loading";
import Paginate from "vuejs-paginate";
import ApiResource from "../../plugins/api";
let apiResource = new ApiResource("career-applications");


export default {
  name: "Document",
  components: { Loading, Paginate},
  data() {
    return {
      name: '',
      order: '',
      status: '',
      type: '',
      image: null,
      edit_id: null,
      isLoading: false,
      errors: [],
      tableHeading: ['SN.', 'Career Id', 'Career Title', 'Name', 'Email', 'Phone No', 'CV File', 'Cover Letter',/* 'Action'*/],
      data: [],
      page: 0,
      endPoint: 'career-applications',


    };
  },
  methods: {
    addButtonEvent() {
      this.formReset();
    },

    async fetchData() {
      this.data = [];
      this.isLoading = true
      try {
        let {data} = await apiResource.list();
        this.data = data.career_applications.data;

        let total = data.career_applications.total;
        let per_page = data.career_applications.per_page;
        this.page = Math.ceil(total / per_page);
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    async handlePagination(pageNum) {
      try {
        this.isLoading = true
        this.paramQuery.page = pageNum;
        let {data} = await apiResource.list(this.paramQuery);
        this.data = data.career_applications.data;
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    storeData(storeUpdateFormData) {
      apiResource.store(storeUpdateFormData)
          .then((response) => {
            this.$toast.success(response.message);
            window.location.reload()
          })
          .catch((error) => {
            this.$toast.error("Please Check the Form. " + error.response.data.message);
            this.errors = error.response.data.errors;
          });
    },
    updateData(storeUpdateFormData) {
      storeUpdateFormData.append('_method', 'put');
      apiResource.update(this.edit_id, storeUpdateFormData)
          .then((response) => {
            this.$toast.success(response.message);
            window.location.reload()
          })
          .catch((error) => {
            this.$toast.error("Please Check the Form. " + error.response.data.message);
            this.errors = error.response.data.errors;
          });
    },
    deleteItem(id, index) {
      if (confirm("Are you sure you want to Delete?")) {
        apiResource.destroy(id)
            .then((response) => {
              this.$toast.success(response.message);
              this.fetchData();
              this.data.splice(index, 1)
            })
            .catch((error) => {
              console.log(error)
            });
      }
    },
    saveOrUpdateData() {
      let storeUpdateFormData = this.prepareFormData();
      let config = {
        header: {
          'Content-Type': 'multipart/form-data'
        }
      }
      if (this.edit_id > 0) {
        this.updateData(storeUpdateFormData, config);
      } else {
        this.storeData(storeUpdateFormData, config)
      }
    },

    prepareFormData() {
      let form_data = new FormData();
      form_data.append('name', this.name);
      form_data.append('status', this.status);
      form_data.append('type', this.type);
      form_data.append('order', this.order);
      form_data.append('image', this.image);

      return form_data;
    },

    editItem(id) {
      let data = this.data.find(data => data.id == id)
      if (data) {
        this.name = data.name
        this.type = data.type
        this.link = data.link
        this.order = data.order
        this.status = data.status
        this.image = data.image
        this.edit_id = data.id
      }
    },
    changeImage(event) {
      this.image = event.target.files[0]
    },
    formReset() {
      this.name = '',
          this.link = '',
          this.status = '',
          this.order = '',
          this.type = '',
          this.image = '',
          this.edit_id = null
    },
  },
  mounted() {
    this.fetchData();
  }
};
</script>