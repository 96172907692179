<template>
  <div class="header-01">
    <div
        class="modal fade modal-password"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Change Password</h5>
            <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="mb-16">
              <label htmlfor="">Old Password</label>
              <div class="form-icon trail">
                <input class="form-control" type="password"/><i
                  class="ic-hidden"
              ></i>
              </div>
            </div>
            <div class="mb-16">
              <label htmlfor="">New Password</label>
              <div class="form-icon trail">
                <input class="form-control" type="password"/><i
                  class="ic-view"
              ></i>
              </div>
            </div>
            <div class="mb-16">
              <label htmlfor="">Confirm NewPassword</label>
              <div class="form-icon trail">
                <input class="form-control" type="password"/><i
                  class="ic-view"
              ></i>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
                type="button"
                class="btn btn-outline-danger"
                data-bs-dismiss="modal"
            >
              Close
            </button>
            <button type="button" class="btn btn-primary">Save changes</button>
          </div>
        </div>
      </div>
    </div>
    <div class="align-center">
      <i class="ic-menu mr-16" @click.prevent="toggleSidebar"></i>
      <h6>Janasachetan Saccos</h6>
    </div>
    <div class="align-center">
      <div class="dropdown dropdown-01 notification">
        <a
            class="active"
            id="dropdownMenuButton1"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
        >
          <i class="ic-notification"></i>
        </a>
        <ul
            class="dropdown-menu list-bullet"
            aria-labelledby="dropdownMenuButton1"
        >
          <div class="flex-center-between">
            <p class="fw-semibold">Notification</p>
            <router-link to="/" class="small text-primary"
            >View all
            </router-link
            >
          </div>
          <li>
            <router-link to="/" class="">
              <small class="clamp-3">
                Rajendra Shrestha has filed a complaint
              </small>
              <small class="text-cGray600 mt-2">10 secs ago</small>
            </router-link>
          </li>
          <li>
            <router-link to="/" class="">
              <small class="clamp-3">
                Rajendra Shrestha has filed a complaint
              </small>
              <small class="text-cGray600 mt-2">10 secs ago</small>
            </router-link>
          </li>
          <li>
            <router-link to="/" class="">
              <small class="clamp-3">
                Rajendra Shrestha has filed a complaint
              </small>
              <small class="text-cGray600 mt-2">10 secs ago</small>
            </router-link>
          </li>
          <li>
            <router-link to="/" class="">
              <small class="clamp-3">
                Rajendra Shrestha has filed a complaint
              </small>
              <small class="text-cGray600 mt-2">10 secs ago</small>
            </router-link>
          </li>
          <li>
            <router-link to="/" class="">
              <small class="clamp-3">
                Rajendra Shrestha has filed a complaint
              </small>
              <small class="text-cGray600 mt-2">10 secs ago</small>
            </router-link>
          </li>
          <li>
            <router-link to="/" class="">
              <small class="clamp-3">
                Rajendra Shrestha has filed a complaint
              </small>
              <small class="text-cGray600 mt-2">10 secs ago</small>
            </router-link>
          </li>
          <li>
            <router-link to="/" class="">
              <small class="clamp-3">
                Rajendra Shrestha has filed a complaint
              </small>
              <small class="text-cGray600 mt-2">10 secs ago</small>
            </router-link>
          </li>
          <li>
            <router-link to="/" class="">
              <small class="clamp-3">
                Rajendra Shrestha has filed a complaint
              </small>
              <small class="text-cGray600 mt-2">10 secs ago</small>
            </router-link>
          </li>
          <li>
            <router-link to="/" class="">
              <small class="clamp-3">
                Rajendra Shrestha has filed a complaint
              </small>
              <small class="text-cGray600 mt-2">10 secs ago</small>
            </router-link>
          </li>
          <li>
            <router-link to="/" class="">
              <small class="clamp-3">
                Rajendra Shrestha has filed a complaint
              </small>
              <small class="text-cGray600 mt-2">10 secs ago</small>
            </router-link>
          </li>
          <li>
            <router-link to="/" class="">
              <small class="clamp-3">
                Rajendra Shrestha has filed a complaint
              </small>
              <small class="text-cGray600 mt-2">10 secs ago</small>
            </router-link>
          </li>
          <li>
            <router-link to="/" class="">
              <small class="clamp-3">
                Rajendra Shrestha has filed a complaint
              </small>
              <small class="text-cGray600 mt-2">10 secs ago</small>
            </router-link>
          </li>
        </ul>
      </div>
      <div class="dropdown dropdown-01">
        <a
            class="p fw-bold align-center"
            id="dropdownMenuButton1"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
        >
          {{ userData ? userData.name : '' }}
          <i class="ic-chevron-bottom ml-8"></i>
        </a>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          <!--          <li data-bs-toggle="modal" data-bs-target="#exampleModal">-->
          <!--            Change Password-->
          <!--          </li>-->
          <li @click.prevent="logout">
            Logout
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
import Auth from "@/Auth";

export default {
  name: "Header",
  data() {
    return {
      isToggled: false,
      userData: [],

    };
  },
  computed: {
    isSelected() {
      return this.$store.state.isSelected;
    },
    isHovered() {
      return this.$store.state.isHovered;
    },
  },
  methods: {
    toggleSidebar() {
      this.isToggled = !this.isToggled;
      this.$emit("clicked", this.isToggled);
    },
    logout() {
      if (confirm("Are you sure you want to log out?")) {
        Auth.logout();
        this.$router.push('/login');
      }
      // if (confirm("Are you sure you want to log out?")) {
      //   axios.post('/logout')
      //       .then(() => {
      //         Auth.logout();
      //         this.$router.push('/login');
      //       })
      //       .catch(error => {
      //         this.errors = error.data.errors;
      //       });
      // }
    }
  },
  mounted() {
    this.userData = JSON.parse(window.localStorage.getItem('user'));
  }
};
</script>
