<template>
  <div class="app-flex-layout">
    <div class="page-header">
      <h5>Audio Video Page</h5>

      <button class="btn btn-sm btn-outline-primary" title="view" data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" @click="addButtonEvent">
        Add New Audio Or Video
      </button>
    </div>

    <!--    OffCanvas Starts-->
    <div class="offcanvas offcanvas-end offcanvas-01" id="offcanvasRight" tabindex="-1"
      aria-labelledby="offcanvasRightLabel">
      <div class="offcanvas-header">
        <h5 class="text-dark" id="offcanvasRightLabel">{{ this.edit_id ? 'Edit Member' : 'Add New Member' }}</h5>
        <button class="btn btn-danger btn-sm" type="button" data-bs-dismiss="offcanvas" aria-label="Close">
          <i class="ic-close"></i>
        </button>
      </div>
      <div class="offcanvas-body">


        <form class="row mt-24" method="POST" @submit.prevent="saveOrUpdateData">
          <div class="col-md-16 mb-16">
            <label for="">Type</label>
            <select v-model="type" id="" class="form-select">
              <option value="">Select Type..</option>
              <option value="audio">Audio</option>
              <option value="video">Video</option>
            </select>
            <VueErrors :errors=this.errors error_name="type" />
          </div>

          <div class="col-md-12 mb-16">
            <label>Name</label>
            <input type="text" v-model="name" class="form-control" placeholder="Name" />
            <VueErrors :errors=this.errors error_name="name" />
          </div>

          <div class="col-md-12 mb-16">
            <label for="">Youtube Video Link</label>
            <input v-model="youtube_video_link" class="form-control" placeholder="Enter Youtube Video Link" />
            <VueErrors :errors=this.errors error_name="youtube_video_link" />
          </div>

          <div class="col-md-12 mb-16">
            <label for="">Audio Link</label>
            <input v-model="audio_link" class="form-control" placeholder="Audio Link" />
            <VueErrors :errors=this.errors error_name="audio_link" />
          </div>


          <div class="col-md-12 mb-16">
            <label>Summary</label>
            <textarea type="text" v-model="summary" class="form-control" placeholder="Summary" />
            <VueErrors :errors=this.errors error_name="summary" />
          </div>
          <div class="col-md-12 mb-16">
            <label>Description</label>
            <textarea type="text" v-model="description" class="form-control" placeholder="Description" />
            <VueErrors :errors=this.errors error_name="description" />
          </div>

          <div class="col-md-12 mb-16">
            <label>Order</label>
            <input type="number" v-model="order" class="form-control" placeholder="Order" />
            <VueErrors :errors=this.errors error_name="order" />

          </div>

          <div class="col-md-12 mb-16">
            <label for="">Upload Audio File</label>
            <input type="file" class="form-control" @change="changeImage($event)" />
            <VueErrors :errors=this.errors error_name="file" />
          </div>

          <div class="col-md-12 mb-16" v-if="edit_id > 0">
            <img src="" :alt="this.file">
          </div>


          <div class="col-md-16 mb-16">
            <label for="">Status</label>
            <select v-model="status" class="form-select">
              <option value="">Select Status..</option>
              <option value="1">Active</option>
              <option value="0">Inactive</option>
            </select>
            <VueErrors :errors=this.errors error_name="status" />
          </div>


          <Loading v-if="isLoading" style="position: absolute;
          top: 90%;
          left: 90%;
          transform: translate(-50%, -50%)" />

          <div class="offcanvas-footer">
            <button type="submit" class="btn btn-green mr-16">
              <i class="ic-checkmark mr-8"></i>
              {{ this.edit_id ? 'Update' : 'Save' }}
            </button>
            <button class="btn btn-red" data-bs-dismiss="offcanvas" aria-label="Close"><i class="ic-close mr-8"></i>
              Cancel
            </button>
          </div>
        </form>
      </div>

    </div>
    <!--    OffCanvas Ends-->


    <div class="pt-24 px-24">
    </div>
    <template>
      <div class="app-flex-layout p-24">
        <div class="d-flex mb-16">
          <div class="dropdown dropdown-01">
          </div>
        </div>
        <div class="position-relative h-100">
          <div class="app-height app-absolute-layout scrollable table-responsive">
            <table class="table table-01">
              <thead>

                <th v-for="item in tableHeading" :key="item.index">{{ item }}</th>
              </thead>
              <tbody>
                <tr v-if="data.length === 0">No Data Found</tr>
                <tr v-for="(item, index) in data" :key="index">
                  <td>{{ ++index }}</td>
                  <td>
                    <div v-if="item.type == 'audio'">Audio</div>
                    <div v-else-if="item.type == 'video'">Video</div>
                  </td>
                  <td>{{ item.name }}</td>
                  <td>
                    <a :href="item ? item.audio_file_path : ''" target="_blank"
                      class="text-white btn btn-primary rounded-pill">
                      Play File
                    </a>
                  </td>
                  <td>
                    <div v-if="item.status == 0" class="badge bg-red rounded-pill">Inactive</div>
                    <div v-else-if="item.status == 1" class="badge bg-green rounded-pill">Active</div>
                  </td>
                  <td>
                    <button title="Edit" class="btn btn-sm btn-gray" data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" @click="editItem(item.id, index)">
                      <i class="ic-edit"></i>
                    </button>
                    <button title="Delete" class="btn btn-sm btn-gray" @click="deleteItem(item.id, index)">
                      <i class="ic-delete"></i>
                    </button>
                  </td>
                </tr>
                <template v-if="isLoading">
                  <Loading />
                </template>
              </tbody>
            </table>
            <div class="table-footer">
              <paginate :page-class="'page-item'" :page-count="page" :page-link-class="'page-link'"
                :container-class="'pagination  pagination-sm'" :prev-text="`<i class='ic-chevron-left'></i>`"
                :next-text="` <i class='ic-chevron-right'></i>`" :prev-class="'page-link pagination-arrow mr-2'"
                :next-class="'page-link pagination-arrow ml-2'" :click-handler="handlePagination"></paginate>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>


<script>
import VueErrors from '@/helper/errors'
import Loading from "../../components/Loading";
import Paginate from "vuejs-paginate";
import ApiResource from "../../plugins/api";
let apiResource = new ApiResource("audio-videos");


export default {
  name: "AudioVideo",
  components: { VueErrors, Loading, Paginate },
  data() {
    return {
      type: '',
      name: '',
      youtube_video_link: null,
      audio_link: null,
      summary: null,
      description: null,
      order: '',
      status: '',
      file: null,
      edit_id: null,
      isLoading: false,
      errors: [],
      tableHeading: ['SN.', 'Type', 'Name', 'File', 'Status', 'Action'],
      data: [],
      page: 0,
      endPoint: 'audio-videos',
      paramQuery: {
        'page': 0
      }

    };
  },
  methods: {
    addButtonEvent() {
      this.formReset();
    },
    async fetchData() {
      this.data = [];
      this.isLoading = true
      try {
        let { data } = await apiResource.list();
        this.data = data.audio_videos.data;

        let total = data.audio_videos.total;
        let per_page = data.audio_videos.per_page;
        this.page = Math.ceil(total / per_page);
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    async handlePagination(pageNum) {
      try {
        this.isLoading = true
        this.paramQuery.page = pageNum;
        let { data } = await apiResource.list(this.paramQuery);
        this.data = data.audio_videos.data;
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    storeData(storeUpdateFormData) {
      this.isLoading = true
      apiResource.store(storeUpdateFormData)
        .then((response) => {
          this.isLoading = false
          this.$toast.success(response.message);
          window.location.reload()
        })
        .catch((error) => {
          this.isLoading = false
          this.$toast.error("Please Check the Form. " + error.response.data.message);
          this.errors = error.response.data.errors;
        });
    },
    updateData(storeUpdateFormData) {
      this.isLoading = true
      storeUpdateFormData.append('_method', 'put');
      apiResource.update(this.edit_id, storeUpdateFormData)
        .then((response) => {
          this.isLoading = false
          this.$toast.success(response.message);
          window.location.reload()
        })
        .catch((error) => {
          this.isLoading = false
          this.$toast.error("Please Check the Form. " + error.response.data.message);
          this.errors = error.response.data.errors;
        });
    },
    deleteItem(id, index) {
      if (confirm("Are you sure you want to Delete?")) {
        apiResource.destroy(id)
          .then((response) => {
            this.$toast.success(response.message);
            this.fetchData();
            this.data.splice(index, 1)
          })
          .catch((error) => {
            console.log(error)
          });
      }
    },
    saveOrUpdateData() {
      let storeUpdateFormData = this.prepareFormData();
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      if (this.edit_id > 0) {
        this.updateData(storeUpdateFormData, config);
      } else {
        this.storeData(storeUpdateFormData, config)
      }
    },

    prepareFormData() {
      let form_data = new FormData();
      form_data.append('type', this.type);
      form_data.append('name', this.name);
      form_data.append('youtube_video_link', this.youtube_video_link);
      form_data.append('audio_link', this.audio_link);
      form_data.append('summary', this.summary);
      form_data.append('description', this.description);
      form_data.append('file', this.file);
      form_data.append('status', this.status);
      form_data.append('order', this.order);

      return form_data;
    },

    editItem(id) {
      let data = this.data.find(data => data.id == id)
      if (data) {
        this.type = data.type
        this.name = data.name
        this.youtube_video_link = data.youtube_video_link
        this.audio_link = data.audio_link
        this.summary = data.summary
        this.description = data.description
        this.file = data.file
        this.status = data.status
        this.order = data.order
        this.edit_id = data.id
      }
    },
    changeImage(event) {
      this.file = event.target.files[0]
    },
    formReset() {
      this.type = '',
        this.name = '',
        this.youtube_video_link = '',
        this.audio_link = '',
        this.summary = '',
        this.description = '',
        this.file = '',
        this.status = '',
        this.order = '',
        this.edit_id = null
    },
  },
  mounted() {
    this.fetchData();
  }
};
</script>
