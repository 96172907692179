import axios from "axios";
// Create axios instance
let token = window.localStorage.getItem('token');
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL + '/api',
    timeout: 100000, // Request timeout
    headers:{"x-token" : token ,'Content-Type' : 'multipart/form-data'}
});
// Request intercepter
service.interceptors.request.use(
    config => {
        // const token = isLogged();
        // if (token) {
        //   config.headers['Authorization'] = 'Bearer ' + isLogged(); // Set JWT token
        // }
        return config;
    },
    error => {
        // Do something with request error
        console.log(error); // for debug
        Promise.reject(error);
    }
);

// response pre-processing
service.interceptors.response.use(
    response => {
        // if (response.headers.authorization) {
        //   setLogged(response.headers.authorization);
        //   response.data.token = response.headers.authorization;
        // }

        return response.data;
    },
    error => {
        // eslint-disable-next-line no-unused-vars
        let message = error.message;
        if (error.response.data && error.response.data.errors) {
            message = error.response.data.errors;
        } else if (error.response.data && error.response.data.error) {
            message = error.response.data.error;
        }
        return Promise.reject(error);
    }
);
export default service;