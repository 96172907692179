<template>
  <div class="app-flex-layout">
    <div class="page-header">
      <h5>Company Setting Page</h5>

      <button
        class="btn btn-sm btn-outline-primary"
        title="view"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasRight"
        aria-controls="offcanvasRight"
        @click="addButtonEvent"
      >
        Add New Company Setting
      </button>
    </div>

    <!--    OffCanvas Starts-->
    <div
      class="offcanvas offcanvas-end offcanvas-01"
      id="offcanvasRight"
      tabindex="-1"
      aria-labelledby="offcanvasRightLabel"
    >
      <div class="offcanvas-header">
        <h5 class="text-dark" id="offcanvasRightLabel">
          {{
            this.edit_id ? "Edit Company Setting" : "Add New Company Setting"
          }}
        </h5>
        <button
          class="btn btn-danger btn-sm"
          type="button"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
          <i class="ic-close"></i>
        </button>
      </div>
      <div class="offcanvas-body">
        <form
          class="row mt-24"
          method="POST"
          @submit.prevent="saveOrUpdateData"
        >
          <div class="col-md-12 mb-16">
            <label>Name</label>
            <input
              type="text"
              v-model="name"
              class="form-control"
              placeholder="Name"
            />
            <VueErrors :errors="this.errors" error_name="name" />
          </div>

          <div class="col-md-12 mb-16">
            <label for="">Address</label>
            <input
              v-model="address"
              class="form-control"
              placeholder="Enter Address"
            />
            <VueErrors :errors="this.errors" error_name="address" />
          </div>

          <div class="col-md-12 mb-16">
            <label>Phone</label>
            <input
              type="text"
              v-model="phone"
              class="form-control"
              placeholder="Phone"
            />
            <VueErrors :errors="this.errors" error_name="phone" />
          </div>

          <div class="col-md-12 mb-16">
            <label>Contact</label>
            <input
              type="text"
              v-model="contact"
              class="form-control"
              placeholder="Contact"
            />
            <VueErrors :errors="this.errors" error_name="contact" />
          </div>

          <div class="col-md-12 mb-16">
            <label>Email</label>
            <input
              type="text"
              v-model="email"
              class="form-control"
              placeholder="Email"
            />
            <VueErrors :errors="this.errors" error_name="email" />
          </div>

          <div class="col-md-12 mb-16">
            <label>Working Hours</label>
            <input
              type="text"
              v-model="working_hours"
              class="form-control"
              placeholder="Working Hours (Eg. Mon-Fri - 10am:5pm)"
            />
            <VueErrors :errors="this.errors" error_name="working_hours" />
          </div>

          <div class="col-md-12 mb-16">
            <label>Google Map</label>
            <input
              type="text"
              v-model="google_map"
              class="form-control"
              placeholder="Google Map Link"
            />
            <VueErrors :errors="this.errors" error_name="google_map" />
          </div>

          <div class="col-md-12 mb-16">
            <label>Fax</label>
            <input
              type="text"
              v-model="fax"
              class="form-control"
              placeholder="Google Map Link"
            />
            <VueErrors :errors="this.errors" error_name="fax" />
          </div>

          <div class="col-md-12 mb-16">
            <label>Description</label>
            <textarea
              type="text"
              v-model="description"
              class="form-control"
              placeholder="Description"
            />
            <VueErrors :errors="this.errors" error_name="description" />
          </div>

          <div class="col-md-12 mb-16">
            <label for="">Logo</label>
            <input
              type="file"
              class="form-control"
              @change="changeImage($event)"
            />
            <VueErrors :errors="this.errors" error_name="image" />
          </div>

          <div class="col-md-12 mb-16" v-if="edit_id > 0">
            <img src="" :alt="this.image" />
          </div>

          <div class="offcanvas-footer">
            <button type="submit" class="btn btn-green mr-16">
              <i class="ic-checkmark mr-8"></i>
              {{ this.edit_id ? "Update" : "Save" }}
            </button>
            <button
              class="btn btn-red"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              <i class="ic-close mr-8"></i>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
    <!--    OffCanvas Ends-->

    <div class="pt-24 px-24"></div>
    <template>
      <div class="app-flex-layout p-24">
        <div class="d-flex mb-16">
          <div class="dropdown dropdown-01"></div>
        </div>
        <div class="position-relative h-100">
          <div
            class="app-height app-absolute-layout scrollable table-responsive"
          >
            <table class="table table-01">
              <thead>
                <th v-for="item in tableHeading" :key="item.index">
                  {{ item }}
                </th>
              </thead>
              <tbody>
                <tr v-if="data.length === 0">
                  No Data Found
                </tr>
                <tr v-for="(item, index) in data" :key="index">
                  <td>{{ ++index }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.phone }}</td>
                  <td>{{ item.email }}</td>
                  <td>
                    <img
                      :src="item.image ? item.image_path : ''"
                      width="100px"
                      height="80px"
                      alt=""
                    />
                  </td>
                  <td class="action">
                    <button
                      title="Edit"
                      class="btn btn-sm btn-gray"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasRight"
                      aria-controls="offcanvasRight"
                      @click="editItem(item.id, index)"
                    >
                      <i class="ic-edit"></i>
                    </button>
                    <button
                      title="Delete"
                      class="btn btn-sm btn-gray"
                      @click="deleteItem(item.id, index)"
                    >
                      <i class="ic-delete"></i>
                    </button>
                  </td>
                </tr>
                <template v-if="isLoading">
                  <Loading />
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>


<script>
import VueErrors from "@/helper/errors";
import Loading from "../../components/Loading";
import ApiResource from "../../plugins/api";

let apiResource = new ApiResource("company-settings");

export default {
  name: "CompanySetting",
  components: { VueErrors, Loading },
  data() {
    return {
      name: "",
      address: null,
      working_hours: null,
      phone: null,
      contact: "",
      email: "",
      google_map: "",
      fax: "",
      description: "",
      image: null,
      edit_id: null,
      isLoading: false,
      errors: [],
      tableHeading: ["SN.", "Name", "Phone", "Email", "Logo", "Action"],
      data: [],
      page: 0,
      endPoint: "company-settings",
      paramQuery: {
        page: 0,
      },
    };
  },
  methods: {
    addButtonEvent() {
      this.formReset();
    },
    async fetchData() {
      this.data = [];
      this.isLoading = true;
      try {
        let { data } = await apiResource.list();
        this.data = data.company_settings;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    storeData(storeUpdateFormData) {
      apiResource
        .store(storeUpdateFormData)
        .then((response) => {
          this.$toast.success(response.message);
          window.location.reload();
        })
        .catch((error) => {
          this.$toast.error(
            "Please Check the Form. " + error.response.data.message
          );
          this.errors = error.response.data.errors;
        });
    },
    updateData(storeUpdateFormData) {
      storeUpdateFormData.append("_method", "put");
      apiResource
        .update(this.edit_id, storeUpdateFormData)
        .then((response) => {
          this.$toast.success(response.message);
          window.location.reload();
        })
        .catch((error) => {
          this.$toast.error(
            "Please Check the Form. " + error.response.data.message
          );
          this.errors = error.response.data.errors;
        });
    },
    deleteItem(id, index) {
      if (confirm("Are you sure you want to Delete?")) {
        apiResource
          .destroy(id)
          .then((response) => {
            this.$toast.success(response.message);
            this.fetchData();
            this.data.splice(index, 1);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    saveOrUpdateData() {
      let storeUpdateFormData = this.prepareFormData();
      let config = {
        header: {
          "Content-Type": "multipart/form-data",
        },
      };
      if (this.edit_id > 0) {
        this.updateData(storeUpdateFormData, config);
      } else {
        this.storeData(storeUpdateFormData, config);
      }
    },

    prepareFormData() {
      let form_data = new FormData();
      form_data.append("name", this.name);
      form_data.append("address", this.address);
      form_data.append("working_hours", this.working_hours);
      form_data.append("phone", this.phone);
      form_data.append("contact", this.contact);
      form_data.append("email", this.email);
      form_data.append("google_map", this.google_map);
      form_data.append("fax", this.fax);
      form_data.append("description", this.description);
      form_data.append("image", this.image);

      return form_data;
    },

    editItem(id) {
      let data = this.data.find((data) => data.id == id);
      if (data) {
        this.name = data.name;
        this.address = data.address;
        this.working_hours = data.working_hours;
        this.phone = data.phone;
        this.contact = data.contact;
        this.email = data.email;
        this.google_map = data.google_map;
        this.fax = data.fax;
        this.description = data.description;
        this.image = data.image;
        this.edit_id = data.id;
      }
    },

    changeImage(event) {
      this.image = event.target.files[0];
    },
    formReset() {
      (this.name = ""),
        (this.address = ""),
        (this.working_hours = ""),
        (this.phone = ""),
        (this.contact = ""),
        (this.email = ""),
        (this.google_map = ""),
        (this.fax = ""),
        (this.description = ""),
        (this.image = ""),
        (this.edit_id = null);
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

