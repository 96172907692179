<template>
  <div class="app-flex-layout">
    <div class="page-header">
      <h5>BOD MEMBER PAGE</h5>

      <button class="btn btn-sm btn-outline-primary"
              title="view"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRight"
              aria-controls="offcanvasRight"
              @click="addButtonEvent">
        Add New Member
      </button>
    </div>

    <!--    OffCanvas Starts-->
    <div
        class="offcanvas offcanvas-end offcanvas-01"
        id="offcanvasRight"
        tabindex="-1"
        aria-labelledby="offcanvasRightLabel"
    >
      <div class="offcanvas-header">
        <h5 class="text-dark" id="offcanvasRightLabel">{{ this.edit_id ? 'Edit Member' : 'Add New Member' }}</h5>
        <button
            class="btn btn-danger btn-sm"
            type="button"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
        >
          <i class="ic-close"></i>
        </button>
      </div>
      <div class="offcanvas-body">

        <form class="row mt-24" method="POST" @submit.prevent="saveOrUpdateData">
          <div class="col-md-16 mb-16">
            <label for="">Member Type</label>
            <select v-model="type" id="" class="form-select">
              <option value="">Select Type..</option>
              <option value="bod">Board of Directors</option>
              <option value="management">Management Team</option>
              <option value="supervisory">Account Supervisory Committee</option>
            </select>
            <VueErrors :errors=this.errors error_name="type"/>
          </div>

          <div class="col-md-12 mb-16">
            <label>Name</label>
            <input type="text" v-model="name" class="form-control" placeholder="Name"/>
            <VueErrors :errors=this.errors error_name="name"/>
          </div>

          <div class="col-md-12 mb-16">
            <label for="">Designation</label>
            <input v-model="designation" class="form-control" placeholder="Enter Designation"/>
            <VueErrors :errors=this.errors error_name="designation"/>
          </div>

          <div class="col-md-12 mb-16">
            <label for="">Phone No</label>
            <input v-model="phone_no" class="form-control" placeholder="Enter Phone No"/>
            <VueErrors :errors=this.errors error_name="phone_no"/>
          </div>

          <div class="col-md-12 mb-16">
            <label for="">Email</label>
            <input v-model="email" class="form-control" placeholder="Enter Email"/>
            <VueErrors :errors=this.errors error_name="email"/>
          </div>

          <div class="col-md-12 mb-16">
            <label for="">Description</label>
            <ckeditor :editor="editor" v-model="description" :config="editorConfig"></ckeditor>
            <VueErrors :errors=this.errors error_name="description"/>
          </div>

          <div class="col-md-12 mb-16">
            <label>Link</label>
            <input type="text" v-model="link" class="form-control" placeholder="Link"/>
            <VueErrors :errors=this.errors error_name="link"/>
          </div>
          <div class="col-md-12 mb-16">
            <label>Facebook Link</label>
            <input type="text" v-model="fb_link" class="form-control"
                   placeholder="Enter facebook Link Eg. https://facebook.com/abc"/>
            <VueErrors :errors=this.errors error_name="fb_link"/>
          </div>
          <div class="col-md-12 mb-16">
            <label>Instagram Link</label>
            <input type="text" v-model="insta_link" class="form-control" placeholder="Enter Insta Link"/>
            <VueErrors :errors=this.errors error_name="insta_link"/>
          </div>
          <div class="col-md-12 mb-16">
            <label>Twitter Link</label>
            <input type="text" v-model="twitter_link" class="form-control" placeholder="Enter Twitter Link"/>
            <VueErrors :errors=this.errors error_name="twitter_link"/>
          </div>
          <div class="col-md-12 mb-16">
            <label>Linkedin Link</label>
            <input type="text" v-model="linkedin_link" class="form-control" placeholder="Enter Linkedin Link"/>
            <VueErrors :errors=this.errors error_name="linkedin_link"/>
          </div>


          <div class="col-md-16 mb-16">
            <label for="">Show in Home</label>
            <select v-model="show_in_home" class="form-select">
              <!--              <option value="">Select ..</option>-->
              <option value="1">Yes</option>
              <option value="0">No</option>
            </select>
            <VueErrors :errors=this.errors error_name="show_in_home"/>
          </div>

          <div class="col-md-12 mb-16">
            <label>Order</label>
            <input type="number" v-model="order" class="form-control" placeholder="Order"/>
            <VueErrors :errors=this.errors error_name="order"/>
          </div>

          <div class="col-md-12 mb-16">
            <label for="">Image</label>
            <input type="file" class="form-control" @change="changeImage($event)"/>
            <VueErrors :errors=this.errors error_name="image"/>
          </div>

          <div class="col-md-12 mb-16" v-if="edit_id > 0">
            <img src="" :alt="this.image">
          </div>


          <div class="col-md-16 mb-16">
            <label for="">Status</label>
            <select v-model="status" class="form-select">
              <option value="">Select Status..</option>
              <option value="1">Active</option>
              <option value="0">Inactive</option>
            </select>
            <VueErrors :errors=this.errors error_name="status"/>
          </div>

          <div class="offcanvas-footer">
            <button type="submit"
                    class="btn btn-green mr-16">
              <i class="ic-checkmark mr-8"></i>
              {{ this.edit_id ? 'Update' : 'Save' }}
            </button>
            <button class="btn btn-red" data-bs-dismiss="offcanvas" aria-label="Close"><i class="ic-close mr-8"></i>
              Cancel
            </button>
          </div>

        </form>
      </div>

    </div>
    <!--    OffCanvas Ends-->


    <div class="pt-24 px-24">
    </div>
    <template>
      <div class="app-flex-layout p-24">
        <div class="d-flex mb-16">
          <div class="dropdown dropdown-01">
          </div>
        </div>
        <div class="position-relative h-100">
          <div class="app-height app-absolute-layout scrollable table-responsive">
            <table class="table table-01">
              <thead>
              <th v-for="item in tableHeading" :key="item.index">{{ item }}</th>
              </thead>
              <tbody>
              <tr v-if="data.length === 0">No Data Found</tr>
              <tr v-for="(item, index) in data" :key="index">
                <td>{{ ++index }}</td>
                <td>
                  <div v-if="item.type === 'bod'">Board of Directors</div>
                  <div v-else-if="item.type === 'management'">Management Team</div>
                  <div v-else-if="item.type === 'supervisory'">Supervisory Committee</div>
                </td>
                <td>{{ item.name }}</td>
                <td>{{ item.designation }}</td>
                <td>{{ item.phone_no }}</td>
                <td>{{ item.email }}</td>
                <td><img :src="item.image ? item.image_path : ''" width="100px" height="80px" alt=""></td>
                <td>
                  <div v-if="item.status === 0" class="badge bg-red rounded-pill">Inactive</div>
                  <div v-else-if="item.status === 1" class="badge bg-green rounded-pill">Active</div>
                </td>
                <td>
                  <button
                      title="Edit"
                      class="btn btn-sm btn-gray"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasRight"
                      aria-controls="offcanvasRight"
                      @click="editItem(item.id, index)"
                  >
                    <i class="ic-edit"></i>
                  </button>
                  <button
                      title="Delete"
                      class="btn btn-sm btn-gray"
                      @click="deleteItem(item.id, index)"
                  >
                    <i class="ic-delete"></i>
                  </button>
                </td>
              </tr>
              <template v-if="isLoading">
                <Loading/>
              </template>
              </tbody>
            </table>
            <div class="table-footer">
              <paginate
                  :page-class="'page-item'"
                  :page-count="page"
                  :page-link-class="'page-link'"
                  :container-class="'pagination  pagination-sm'"
                  :prev-text="`<i class='ic-chevron-left'></i>`"
                  :next-text="` <i class='ic-chevron-right'></i>`"
                  :prev-class="'page-link pagination-arrow mr-2'"
                  :next-class="'page-link pagination-arrow ml-2'"
                  :click-handler="handlePagination"
              ></paginate>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>


<script>
import VueErrors from '@/helper/errors'
import Loading from "../../components/Loading";
import Paginate from "vuejs-paginate";
import ApiResource from "@/plugins/api";
import CKEditor from "ckeditor4-vue";
// import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

let apiResource = new ApiResource("members");

export default {
  name: "Bod",
  components: {VueErrors, Loading, Paginate, ckeditor: CKEditor.component,},
  data() {
    return {
      name: '',
      link: null,
      designation: null,
      phone_no: null,
      email: null,
      description: null,
      order: '',
      status: '',
      show_in_home: 0,
      type: '',
      image: null,
      linkedin_link: null,
      twitter_link: null,
      fb_link: null,
      insta_link: null,
      edit_id: null,
      isLoading: false,
      errors: [],
      tableHeading: ['SN.', 'Type', 'Name', 'Designation', 'Phone', 'Email', 'Image', 'Status', 'Action'],
      data: [],
      page: 0,
      editor: ClassicEditor,
      editorConfig: {
        // The configuration of the editor.
        height: "150px",
        allowedContent: true,
        extraAllowedContent: "iframe[*]",
      },
      endPoint: 'members',
      paramQuery: {
        'page': 0,
        'type': 'bod'
      },
    };
  },
  methods: {
    addButtonEvent() {
      this.formReset();
    },
    async fetchData() {
      this.data = [];
      this.isLoading = true
      try {
        let {data} = await apiResource.list({'type': 'bod'});
        this.data = data.members.data;

        let total = data.members.total;
        let per_page = data.members.per_page;
        this.page = Math.ceil(total / per_page);
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    async handlePagination(pageNum) {
      try {
        this.isLoading = true
        this.paramQuery.page = pageNum;
        let {data} = await apiResource.list(this.paramQuery);
        this.data = data.members.data;
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    storeData(storeUpdateFormData) {
      apiResource.store(storeUpdateFormData)
          .then((response) => {
            this.$toast.success(response.message);
            window.location.reload()
          })
          .catch((error) => {
            this.$toast.error("Please Check the Form. " + error.response.data.message);
            this.errors = error.response.data.errors;
          });
    },
    updateData(storeUpdateFormData) {
      storeUpdateFormData.append('_method', 'put');
      apiResource.update(this.edit_id, storeUpdateFormData)
          .then((response) => {
            this.$toast.success(response.message);
            window.location.reload()
          })
          .catch((error) => {
            this.$toast.error("Please Check the Form. " + error.response.data.message);
            this.errors = error.response.data.errors;
          });
    },
    deleteItem(id, index) {
      if (confirm("Are you sure you want to Delete?")) {
        apiResource.destroy(id)
            .then((response) => {
              this.$toast.success(response.message);
              this.fetchData();
              this.data.splice(index, 1)
            })
            .catch((error) => {
              console.log(error)
            });
      }
    },
    saveOrUpdateData() {
      let storeUpdateFormData = this.prepareFormData();
      if (this.edit_id > 0) {
        this.updateData(storeUpdateFormData);
      } else {
        this.storeData(storeUpdateFormData)
      }
    },

    prepareFormData() {
      let form_data = new FormData();
      form_data.append('name', this.name);
      form_data.append('link', this.link);
      form_data.append('designation', this.designation);
      form_data.append('phone_no', this.phone_no);
      form_data.append('email', this.email);
      form_data.append('description', this.description);
      form_data.append('status', this.status);
      form_data.append('show_in_home', this.show_in_home);
      form_data.append('type', this.type);
      form_data.append('order', this.order);
      form_data.append('image', this.image);
      form_data.append('linkedin_link', this.linkedin_link);
      form_data.append('twitter_link', this.twitter_link);
      form_data.append('fb_link', this.fb_link);
      form_data.append('insta_link', this.insta_link);

      return form_data;
    },
    editItem(id) {
      let data = this.data.find(data => data.id == id)
      if (data) {
        this.name = data.name
        this.type = data.type
        this.designation = data.designation
        this.phone_no = data.phone_no
        this.email = data.email
        this.description = data.description
        this.link = data.link
        this.order = data.order
        this.status = data.status
        this.show_in_home = data.show_in_home
        this.image = data.image
        this.linkedin_link = data.linkedin_link
        this.twitter_link = data.twitter_link
        this.fb_link = data.fb_link
        this.insta_link = data.insta_link
        this.edit_id = data.id
      }
    },
    changeImage(event) {
      this.image = event.target.files[0]
    },
    formReset() {
      this.name = '',
          this.link = '',
          this.designation = '',
          this.phone_no = '',
          this.email = '',
          this.description = '',
          this.status = '',
          this.show_in_home = 0,
          this.order = '',
          this.type = '',
          this.linkedin_link = '',
          this.twitter_link = '',
          this.fb_link = '',
          this.insta_link = '',
          this.image = '',
          this.edit_id = null
    },
  },
  mounted() {
    this.fetchData();
  }
};
</script>