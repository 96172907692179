<template>
  <span class="text-danger" v-if="errors[error_name]">{{ errors[error_name][0] }}</span>
</template>
<script>

export default {
  name: "VueError",
  props: [
    'errors',
    'error_name'
  ],
};
</script>