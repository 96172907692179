<template>
  <div class="app-flex-layout">
    <div class="page-header">
      <h5>LOAN APPLICATION PAGE</h5>
      <a class="btn btn-sm btn-outline-primary" href="#" @click.prevent="exportApplication">Export Loan Applications</a>
    </div>

    <!--    OffCanvas Starts-->
    <div
        class="offcanvas offcanvas-end offcanvas-01"
        id="offcanvasRight"
        tabindex="-1"
        aria-labelledby="offcanvasRightLabel"
    >
      <div class="offcanvas-header">
        <h5 class="text-dark" id="offcanvasRightLabel">{{ this.edit_id ? 'Edit Sitepage' : 'Add New Sitepage' }}</h5>
        <button
            class="btn btn-danger btn-sm"
            type="button"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
        >
          <i class="ic-close"></i>
        </button>
      </div>
      <div class="offcanvas-body">

        <div class="col-md-12 mb-16">
          <label>Service center</label>
          <input type="text" v-model="service_center" class="form-control" placeholder="Service Center" disabled/>
          <VueErrors :errors=this.errors error_name="service_center"/>
        </div>

        <div class="col-md-12 mb-16">
          <label>Purpose of Loan</label>
          <input type="text" v-model="purpose_of_loan" class="form-control" placeholder="Loan Purpose" disabled/>
          <VueErrors :errors=this.errors error_name="purpose_of_loan"/>
        </div>

        <div class="col-md-12 mb-16">
          <label>Loan Amount</label>
          <input type="text" v-model="loan_amount" class="form-control" placeholder="Loan Amount" disabled/>
          <VueErrors :errors=this.errors error_name="loan_amount"/>
        </div>

        <div class="col-md-12 mb-16">
          <label>Full Name</label>
          <input type="text" v-model="full_name" class="form-control" placeholder="Full Name" disabled/>
          <VueErrors :errors=this.errors error_name="full_name"/>
        </div>

        <div class="col-md-12 mb-16">
          <label>Age</label>
          <input type="text" v-model="age" class="form-control" placeholder="Age" disabled/>
          <VueErrors :errors=this.errors error_name="age"/>
        </div>
        <div class="col-md-12 mb-16">
          <label>Full Name</label>
          <input type="text" v-model="full_name" class="form-control" placeholder="Full Name" disabled/>
          <VueErrors :errors=this.errors error_name="full_name"/>
        </div>
        <div class="col-md-12 mb-16">
          <label>Occupation</label>
          <input type="text" v-model="occupation" class="form-control" placeholder="Occupation" disabled/>
          <VueErrors :errors=this.errors error_name="occupation"/>
        </div>
        <div class="col-md-12 mb-16">
          <label>Permanent Address</label>
          <input type="text" v-model="permanent_address" class="form-control" placeholder="Permanent Address" disabled/>
          <VueErrors :errors=this.errors error_name="permanent_address"/>
        </div>
        <div class="col-md-12 mb-16">
          <label>Street</label>
          <input type="text" v-model="street" class="form-control" placeholder="Street" disabled/>
          <VueErrors :errors=this.errors error_name="street"/>
        </div>
        <div class="col-md-12 mb-16">
          <label>Mobile No</label>
          <input type="text" v-model="mobile_no" class="form-control" placeholder="Mobile No" disabled/>
          <VueErrors :errors=this.errors error_name="mobile_no"/>
        </div>
        <div class="col-md-12 mb-16">
          <label>Telephone No</label>
          <input type="text" v-model="telephone_no" class="form-control" placeholder="Telephone No" disabled/>
          <VueErrors :errors=this.errors error_name="telephone_no"/>
        </div>

        <div class="col-md-12 mb-16">
          <label>Fathers / Husband Name</label>
          <input type="text" v-model="fathers_or_husband_name" class="form-control" placeholder="Fathers / Husband Name"
                 disabled/>
          <VueErrors :errors=this.errors error_name="fathers_or_husband_name"/>
        </div>

        <div class="col-md-12 mb-16">
          <label>Grandfather Or Father In Law Name</label>
          <input type="text" v-model="grandfathers_or_father_in_laws_name" class="form-control"
                 placeholder="Grandfather Or Father In Law Name" disabled/>
          <VueErrors :errors=this.errors error_name="fathers_or_husband_name"/>
        </div>

        <div class="col-md-12 mb-16">
          <label>Nearest Famous Address</label>
          <input type="text" v-model="nearest_famous_address" class="form-control" placeholder="Nearest Famous Address"
                 disabled/>
          <VueErrors :errors=this.errors error_name="nearest_famous_address"/>
        </div>

        <div class="col-md-16 mb-16">
          <label for="">Viewed by Admin</label>
          <select v-model="viewed_by_admin" class="form-select">
            <option value="">Select Status..</option>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
          <VueErrors :errors=this.errors error_name="viewed_by_admin"/>
        </div>

        <div class="col-md-12 mb-16" v-if="edit_id > 0">
          <img src="" :alt="this.image">
        </div>

        <div class="offcanvas-footer">
          <button type="submit"
                  class="btn btn-green mr-16" @click="saveOrUpdateData">
            <i class="ic-checkmark mr-8"></i>
            {{ this.edit_id ? 'Update' : 'Save' }}
          </button>
          <button class="btn btn-red" data-bs-dismiss="offcanvas" aria-label="Close"><i class="ic-close mr-8"></i>
            Cancel
          </button>
        </div>

      </div>
    </div>

    <!--    OffCanvas Ends-->


    <div class="pt-24 px-24">
    </div>
    <template>
      <div class="app-flex-layout p-24">
        <div class="d-flex mb-16">
          <div class="dropdown dropdown-01">
          </div>
        </div>
        <div class="position-relative h-100">
          <div class="app-height app-absolute-layout scrollable table-responsive">
            <table class="table table-01">
              <thead>
              <th v-for="item in tableHeading" :key="item.index">{{ item }}</th>
              </thead>
              <tbody>
              <tr v-if="data.length === 0">No Loan Application Found</tr>
              <tr v-for="(item, index) in data" :key="index">
                <td>{{ ++index }}</td>
                <td>{{ item.service_center }}</td>
                <td>{{ item.purpose_of_loan }}</td>
                <td>{{ item.loan_amount }}</td>
                <td>{{ item.full_name }}</td>
                <td>
                  <div v-if="item.viewed_by_admin === 0" class="badge bg-red rounded-pill">No</div>
                  <div v-else-if="item.viewed_by_admin === 1" class="badge bg-green rounded-pill">Yes</div>
                </td>
                <td class="action">
                  <button
                      title="Edit"
                      class="btn btn-sm btn-gray"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasRight"
                      aria-controls="offcanvasRight"
                      @click="editItem(item.id, index)"
                  >
                    <i class="ic-view"></i>
                  </button>
                  <button
                      title="Delete"
                      class="btn btn-sm btn-gray"
                      @click="deleteItem(item.id, index)"
                  >
                    <i class="ic-delete"></i>
                  </button>
                </td>
              </tr>
              <template v-if="isLoading">
                <Loading/>
              </template>
              </tbody>
            </table>
            <div class="table-footer">
              <paginate
                  :page-class="'page-item'"
                  :page-count="page"
                  :page-link-class="'page-link'"
                  :container-class="'pagination  pagination-sm'"
                  :prev-text="`<i class='ic-chevron-left'></i>`"
                  :next-text="` <i class='ic-chevron-right'></i>`"
                  :prev-class="'page-link pagination-arrow mr-2'"
                  :next-class="'page-link pagination-arrow ml-2'"
                  :click-handler="handlePagination"
              ></paginate>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>


<script>
import VueErrors from '@/helper/errors'
import Loading from "../../components/Loading";
import Paginate from "vuejs-paginate";
import ApiResource from "../../plugins/api";

let apiResource = new ApiResource("loan-applications");

export default {
  name: "LoanApplication",
  components: {VueErrors, Loading, Paginate},
  data() {
    return {
      service_center: '',
      purpose_of_loan: '',
      loan_amount: null,
      amount_in_words: null,
      full_name: null,
      age: null,
      occupation: null,
      permanent_address: null,
      street: '',
      mobile_no: '',
      telephone_no: '',
      nearest_famous_address: null,
      fathers_or_husband_name: null,
      grandfathers_or_father_in_laws_name: null,
      viewed_by_admin: '',
      edit_id: null,
      isLoading: false,
      errors: [],
      tableHeading: ['SN.', 'Service Center', 'Purpose of Loan', 'Loan Amount', 'Full Name', 'Application Viewed', 'Action'],
      data: [],
      page: 0,
      endPoint: 'loan-applications',
      paramQuery: {
        'page': 0
      }


    };
  },
  methods: {
    async exportApplication() {

      apiResource.exportFile('export')
          .then((responseData) => {
            const fileURL = window.URL.createObjectURL(new Blob([responseData]));
            const fileLink = document.createElement('a');
            console.log(fileLink)

            fileLink.href = fileURL;
            fileLink.setAttribute('download', 'LoanApplications.csv');
            document.body.appendChild(fileLink);

            fileLink.click();
          });
    },

    async fetchData() {
      this.data = [];
      this.isLoading = true
      try {
        let {data} = await apiResource.list();
        this.data = data.loan_application.data;

        let total = data.loan_application.total;
        let per_page = data.loan_application.per_page;
        this.page = Math.ceil(total / per_page);
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    async handlePagination(pageNum) {
      try {
        this.isLoading = true
        this.paramQuery.page = pageNum;
        let {data} = await apiResource.list(this.paramQuery);
        this.data = data.loan_application.data;
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    storeData(storeUpdateFormData) {
      apiResource.store(storeUpdateFormData)
          .then((response) => {
            this.$toast.success(response.message);
            window.location.reload()
          })
          .catch((error) => {
            this.$toast.error("Please Check the Form. " + error.response.data.message);
            this.errors = error.response.data.errors;
          });
    },
    updateData(storeUpdateFormData) {
      storeUpdateFormData.append('_method', 'put');
      apiResource.update(this.edit_id, storeUpdateFormData)
          .then((response) => {
            this.$toast.success(response.message);
            window.location.reload()
          })
          .catch((error) => {
            this.$toast.error("Please Check the Form. " + error.response.data.message);
            this.errors = error.response.data.errors;
          });
    },
    deleteItem(id, index) {
      if (confirm("Are you sure you want to Delete?")) {
        apiResource.destroy(id)
            .then((response) => {
              this.$toast.success(response.message);
              this.fetchData();
              this.data.splice(index, 1)
            })
            .catch((error) => {
              console.log(error)
            });
      }
    },

    saveOrUpdateData() {
      let storeUpdateFormData = this.prepareFormData();
      if (this.edit_id > 0) {
        this.updateData(storeUpdateFormData);
      } else {
        this.storeData(storeUpdateFormData)
      }
    },

    addButtonEvent() {
      this.formReset();
    },

    prepareFormData() {
      let form_data = new FormData();
      form_data.append('service_center', this.service_center);
      form_data.append('purpose_of_loan', this.purpose_of_loan);
      form_data.append('loan_amount', this.loan_amount);
      form_data.append('amount_in_words', this.amount_in_words);
      form_data.append('full_name', this.full_name);
      form_data.append('age', this.age);
      form_data.append('occupation', this.occupation);
      form_data.append('permanent_address', this.permanent_address);
      form_data.append('street', this.street);
      form_data.append('mobile_no', this.mobile_no);
      form_data.append('telephone_no', this.telephone_no);
      form_data.append('fathers_or_husband_name', this.fathers_or_husband_name);
      form_data.append('grandfathers_or_father_in_laws_name', this.grandfathers_or_father_in_laws_name);
      form_data.append('nearest_famous_address', this.nearest_famous_address);
      form_data.append('viewed_by_admin', this.viewed_by_admin);

      return form_data;
    },

    editItem(id) {
      let data = this.data.find(data => data.id == id)
      if (data) {
        this.service_center = data.service_center
        this.purpose_of_loan = data.purpose_of_loan
        this.loan_amount = data.loan_amount
        this.amount_in_words = data.amount_in_words
        this.full_name = data.full_name
        this.age = data.age
        this.occupation = data.occupation
        this.permanent_address = data.permanent_address
        this.street = data.street
        this.mobile_no = data.mobile_no
        this.telephone_no = data.telephone_no
        this.fathers_or_husband_name = data.fathers_or_husband_name
        this.grandfathers_or_father_in_laws_name = data.grandfathers_or_father_in_laws_name
        this.nearest_famous_address = data.nearest_famous_address
        this.viewed_by_admin = data.viewed_by_admin
        this.edit_id = data.id
      }
    },
    changeImage(event) {
      this.image = event.target.files[0]
    },

    formReset() {
      this.service_center = ''
      this.purpose_of_loan = ''
      this.loan_amount = ''
      this.amount_in_words = ''
      this.full_name = ''
      this.age = ''
      this.occupation = ''
      this.permanent_address = ''
      this.street = ''
      this.mobile_no = ''
      this.telephone_no = ''
      this.fathers_or_husband_name = ''
      this.grandfathers_or_father_in_laws_name = ''
      this.nearest_famous_address = ''
      this.viewed_by_admin = ''
      this.edit_id = null
    },
  },
  mounted() {
    this.fetchData();
  }
};
</script>