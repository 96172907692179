<template>
  <div class="app-flex-layout">
    <div class="page-header">
      <h5>Service Level Three (Child-Category)</h5>

      <button class="btn btn-sm btn-outline-primary"
              title="view"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRight"
              aria-controls="offcanvasRight"
              @click="addButtonEvent">
        Add New Service Child-Category
      </button>
    </div>

    <!--    OffCanvas Starts-->
    <div
        class="offcanvas offcanvas-end offcanvas-01"
        id="offcanvasRight"
        tabindex="-1"
        aria-labelledby="offcanvasRightLabel"
    >
      <div class="offcanvas-header">
        <h5 class="text-dark" id="offcanvasRightLabel">
          {{
            this.edit_id ? 'Edit Service Sub-Category (Level Three)' : 'Add New Service Sub-Category (Level Three)'
          }}</h5>
        <button
            class="btn btn-danger btn-sm"
            type="button"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
        >
          <i class="ic-close"></i>
        </button>
      </div>
      <div class="offcanvas-body">

        <div class="col-md-16 mb-16">
          <label for="">Choose Service Category</label>
          <select v-model="parent_id" class="form-select">
            <option value="">Select Category..</option>
            <option :value="parent.id" v-for="parent in parentLevelTwoData" :key="parent.id">{{ parent.name }}</option>
          </select>
          <VueErrors :errors=this.errors error_name="parent_id"/>
        </div>

        <div class="col-md-12 mb-16">
          <label>Name</label>
          <input type="text" v-model="name" class="form-control" placeholder="Name"/>
          <VueErrors :errors=this.errors error_name="name"/>
        </div>

        <div class="col-md-12 mb-16">
          <label for="">Summary</label>
          <textarea v-model="summary" class="form-control" placeholder="Summary Here .."></textarea>
          <VueErrors :errors=this.errors error_name="summary"/>
        </div>

        <div class="col-md-12 mb-16">
          <label for="">Description</label>
          <ckeditor :editor="editor" v-model="description" :config="editorConfig"></ckeditor>
          <VueErrors :errors=this.errors error_name="description"/>
        </div>

        <div class="col-md-16 mb-16">
          <label for="">Show in Home</label>
          <select v-model="show_in_home" class="form-select">
            <option value="">Select ..</option>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
          <VueErrors :errors=this.errors error_name="show_in_home"/>
        </div>

        <div class="col-md-12 mb-16">
          <label>Icon</label>
          <input type="text" v-model="icon" class="form-control" placeholder="Icon (Eg. ic-mobile)"/>
          <VueErrors :errors=this.errors error_name="icon"/>
        </div>

        <div class="col-md-12 mb-16">
          <label>Order</label>
          <input type="number" v-model="order" class="form-control" placeholder="Order"/>
          <VueErrors :errors=this.errors error_name="order"/>
        </div>

        <div class="col-md-12 mb-16">
          <label for="">Image</label>
          <input type="file" class="form-control" @change="changeImage($event)"/>
          <VueErrors :errors=this.errors error_name="image"/>
        </div>

        <div class="col-md-12 mb-16" v-if="edit_id > 0">
          <img src="" :alt="this.image">
        </div>


        <div class="col-md-16 mb-16">
          <label for="">Status</label>
          <select v-model="status" class="form-select">
            <option value="">Select Status..</option>
            <option value="1">Active</option>
            <option value="0">Inactive</option>
          </select>
          <VueErrors :errors=this.errors error_name="status"/>
        </div>

        <div class="offcanvas-footer">
          <button type="submit"
                  class="btn btn-green mr-16" @click="saveOrUpdateData">
            <i class="ic-checkmark mr-8"></i>
            {{ this.edit_id ? 'Update' : 'Save' }}
          </button>
          <button class="btn btn-red" data-bs-dismiss="offcanvas" aria-label="Close"><i class="ic-close mr-8"></i>
            Cancel
          </button>
        </div>

      </div>
    </div>

    <!--    OffCanvas Ends-->


    <div class="pt-24 px-24">
    </div>
    <template>
      <div class="app-flex-layout p-24">
        <div class="d-flex mb-16">
          <div class="dropdown dropdown-01">
          </div>
        </div>
        <div class="position-relative h-100">
          <div class="app-height app-absolute-layout scrollable table-responsive">
            <table class="table table-01">
              <thead>

              <th v-for="item in tableHeading" :key="item.index">{{ item }}</th>
              </thead>
              <tbody>
              <tr v-if=" data && data.length === 0">No Data Found</tr>
              <tr v-for="(item, index) in data" :key="index">
                <td>{{ ++index }}</td>
                <td>{{ item && item.parent && item.parent.parent ? item.parent.parent.name : '' }}</td>
                <td>{{ item && item.parent ? item.parent.name : '' }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.slug }}</td>
                <td>{{ item.order }}</td>
                <td><img :src="item.image ? item.image_path : ''" style="height: 50px; width: 100px"></td>
                <td class="">
                  <button
                      title="Edit"
                      class="btn btn-sm btn-gray"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasRight"
                      aria-controls="offcanvasRight"
                      @click="editItem(item.id, index)"
                  >
                    <i class="ic-edit"></i>
                  </button>
                  <button
                      title="Delete"
                      class="btn btn-sm btn-gray"
                      @click="deleteItem(item.id, index)"
                  >
                    <i class="ic-delete"></i>
                  </button>
                </td>
              </tr>
              <template v-if="isLoading">
                <Loading/>
              </template>
              </tbody>
            </table>
            <div class="table-footer">
              <paginate
                  :page-class="'page-item'"
                  :page-count="page"
                  :page-link-class="'page-link'"
                  :container-class="'pagination  pagination-sm'"
                  :prev-text="`<i class='ic-chevron-left'></i>`"
                  :next-text="` <i class='ic-chevron-right'></i>`"
                  :prev-class="'page-link pagination-arrow mr-2'"
                  :next-class="'page-link pagination-arrow ml-2'"
                  :click-handler="handlePagination"
              ></paginate>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>


<script>
import VueErrors from '@/helper/errors'
import Loading from "../../components/Loading";
import Paginate from "vuejs-paginate";
import CKEditor from "ckeditor4-vue";
// import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ApiResource from "@/plugins/api";
let apiResource = new ApiResource("services");


export default {
  name: "ServiceLevelTwo",
  components: {VueErrors, Loading, Paginate, ckeditor: CKEditor.component,},
  data() {
    return {
      parent_id: 0,
      type: 'service_level_three',
      name: '',
      slug: '',
      summary: null,
      description: null,
      order: '',
      show_in_home: '',
      icon: '',
      status: '',
      image: null,
      edit_id: null,
      isLoading: false,
      errors: [],
      tableHeading: ['SN.', 'Category', 'Sub-Category', 'Name', 'Slug', 'Order', 'Image', 'Action'],
      data: [],
      parentLevelTwoData: [],
      page: 0,
      endPoint: 'services',
      editor: ClassicEditor,
      paramQuery: {
        'type': 'service_level_three',
        'page': 0,
      },
      editorConfig: {
        // The configuration of the editor.
        height: "150px",
        allowedContent: true,
        extraAllowedContent: "iframe[*]",
      },
    };
  },
  methods: {
    addButtonEvent() {
      this.formReset();
    },

    async fetchData() {
      this.data = [];
      this.isLoading = true
      try {
        let {data} = await apiResource.list({type: 'service_level_three'});
        this.data = data.service_level_three.data;
        this.parentLevelTwoData = data.service_level_two;

        let total = data.service_level_three.total;
        let per_page = data.service_level_three.per_page;
        this.page = Math.ceil(total / per_page);
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    async handlePagination(pageNum) {
      this.isLoading = true
      try {
        this.paramQuery.page = pageNum;
        let {data} = await apiResource.list(this.paramQuery);
        this.data = data.service_level_three.data;

      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    storeData(storeUpdateFormData) {
      apiResource.store(storeUpdateFormData)
          .then((response) => {
            this.$toast.success(response.message);
            window.location.reload()
          })
          .catch((error) => {
            this.$toast.error("Please Check the Form. " + error.response.data.message);
            this.errors = error.response.data.errors;
          });
    },
    updateData(storeUpdateFormData) {
      storeUpdateFormData.append('_method', 'put');
      apiResource.update(this.edit_id, storeUpdateFormData)
          .then((response) => {
            this.$toast.success(response.message);
            window.location.reload()
          })
          .catch((error) => {
            this.$toast.error("Please Check the Form. " + error.response.data.message);
            this.errors = error.response.data.errors;
          });
    },
    deleteItem(id, index) {
      if (confirm("Are you sure you want to Delete?")) {
        apiResource.destroy(id)
            .then((response) => {
              this.$toast.success(response.message);
              this.fetchData();
              this.data.splice(index, 1)
            })
            .catch((error) => {
              console.log(error)
            });
      }
    },
    saveOrUpdateData() {
      let storeUpdateFormData = this.prepareFormData();
      if (this.edit_id > 0) {
        this.updateData(storeUpdateFormData);
      } else {
        this.storeData(storeUpdateFormData)
      }
    },

    prepareFormData() {
      let form_data = new FormData();
      form_data.append('parent_id', this.parent_id);
      form_data.append('type', 'service_level_three');
      form_data.append('name', this.name);
      form_data.append('summary', this.summary);
      form_data.append('description', this.description);
      form_data.append('show_in_home', this.show_in_home);
      form_data.append('icon', this.icon);
      form_data.append('status', this.status);
      form_data.append('order', this.order);
      form_data.append('image', this.image);

      return form_data;
    },

    editItem(id) {
      let data = this.data.find(data => data.id == id)
      if (data) {
        this.parent_id = data.parent_id
        this.name = data.name
        this.summary = data.summary
        this.description = data.description
        this.show_in_home = data.show_in_home
        this.icon = data.icon
        this.order = data.order
        this.status = data.status
        this.image = data.image
        this.edit_id = data.id
      }
    },
    changeImage(event) {
      this.image = event.target.files[0]
    },
    formReset() {
      this.parent_id = '',
          this.name = '',
          this.link = '',
          this.summary = '',
          this.description = '',
          this.show_in_home = '',
          this.icon = '',
          this.status = '',
          this.order = '',
          this.type = '',
          this.image = '',
          this.edit_id = null
    },
  },
  mounted() {
    this.fetchData();
  }
};
</script>