<template>
  <div class="app-flex-layout">
    <div class="page-header">
      <h5>SITEPAGE PAGE</h5>

      <button class="btn btn-sm btn-outline-primary"
              title="view"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRight"
              aria-controls="offcanvasRight"
              @click="addButtonEvent">
        Add New Sitepage
      </button>
    </div>

    <!--    OffCanvas Starts-->
    <div
        class="offcanvas offcanvas-end offcanvas-01"
        id="offcanvasRight"
        tabindex="-1"
        aria-labelledby="offcanvasRightLabel"
    >
      <div class="offcanvas-header">
        <h5 class="text-dark" id="offcanvasRightLabel">{{ this.edit_id ? 'Edit Sitepage' : 'Add New Sitepage' }}</h5>
        <button
            class="btn btn-danger btn-sm"
            type="button"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
        >
          <i class="ic-close"></i>
        </button>
      </div>
      <div class="offcanvas-body">


        <div class="col-md-12 mb-16">
          <label>Name</label>
          <input type="text" v-model="name" class="form-control" placeholder="Name"/>
          <VueErrors :errors=this.errors error_name="name"/>
        </div>

        <div class="col-md-12 mb-16">
          <label for="">Sub Title</label>
          <textarea v-model="subtitle" class="form-control" placeholder="Sub Title Here .."></textarea>
          <VueErrors :errors=this.errors error_name="subtitle"/>
        </div>


        <div class="col-md-12 mb-16">
          <label for="">Summary</label>
          <textarea v-model="summary" class="form-control" placeholder="Summary Here .."></textarea>
          <VueErrors :errors=this.errors error_name="summary"/>
        </div>

        <div class="col-md-12 mb-16">
          <label for="">Description</label>
          <ckeditor :editor="editor" v-model="description" :config="editorConfig"></ckeditor>
          <VueErrors :errors=this.errors error_name="description"/>
        </div>

        <div class="col-md-12 mb-16">
          <label for="">Listing Items (For Eg. Objectives List)</label>
          <table class="table table-01">
            <tr v-for="(item,index) in listing_items" :key="index">
              <td>
                <input type="text" v-model="item.listing" class="form-control"
                       :placeholder="'Listing Items ' + (index + 1)"/>
              </td>
              <td>
                <button name="button" class="btn btn-outline-primary" @click.prevent="addListing"
                        v-show="index === listing_items.length-1">
                  <i class="ic-plus"></i>
                </button>
              </td>
              <td>
                <button name="button1" class="btn btn-outline-primary" @click.prevent="removeListing(index)"
                        v-show="index === listing_items.length-1">
                  <i class="ic-minus"></i>
                </button>
              </td>
            </tr>
          </table>
          <VueErrors :errors=this.errors error_name="salary_and_benefits"/>
        </div>

        <div class="col-md-12 mb-16">
          <label>Link</label>
          <input type="text" v-model="link" class="form-control" placeholder="Link"/>
          <VueErrors :errors=this.errors error_name="link"/>
        </div>

        <div class="col-md-12 mb-16">
          <label>Android Url</label>
          <input type="text" v-model="android_url" class="form-control" placeholder="Android URL"/>
          <VueErrors :errors=this.errors error_name="addroid_url"/>
        </div>

        <div class="col-md-12 mb-16">
          <label>iOS Url</label>
          <input type="text" v-model="ios_url" class="form-control" placeholder="iOS URL"/>
          <VueErrors :errors=this.errors error_name="ios_url"/>
        </div>

        <div class="col-md-12 mb-16">
          <label>Order</label>
          <input type="number" v-model="order" class="form-control" placeholder="Order"/>
          <VueErrors :errors=this.errors error_name="order"/>

        </div>

        <div class="col-md-12 mb-16">
          <label for="">Image</label>
          <input type="file" class="form-control" @change="changeImage($event)"/>
          <VueErrors :errors=this.errors error_name="image"/>
        </div>

        <div class="col-md-12 mb-16" v-if="edit_id > 0">
          <img src="" :alt="this.image">
        </div>


        <!--        <div class="col-md-16 mb-16">-->
        <!--          <label for="">Status</label>-->
        <!--          <select v-model="status" class="form-select">-->
        <!--            <option value="">Select Status..</option>-->
        <!--            <option value="1">Active</option>-->
        <!--            <option value="0">Inactive</option>-->
        <!--          </select>-->
        <!--          <VueErrors :errors=this.errors error_name="status"/>-->
        <!--        </div>-->

        <div class="offcanvas-footer">
          <button type="submit"
                  class="btn btn-green mr-16" @click="saveOrUpdateData">
            <i class="ic-checkmark mr-8"></i>
            {{ this.edit_id ? 'Update' : 'Save' }}
          </button>
          <button class="btn btn-red" data-bs-dismiss="offcanvas" aria-label="Close"><i class="ic-close mr-8"></i>
            Cancel
          </button>
        </div>

      </div>
    </div>

    <!--    OffCanvas Ends-->


    <div class="pt-24 px-24">
    </div>
    <template>
      <div class="app-flex-layout p-24">
        <div class="d-flex mb-16">
          <div class="dropdown dropdown-01">
          </div>
        </div>
        <div class="position-relative h-100">
          <div class="app-height app-absolute-layout scrollable table-responsive">
            <table class="table table-01">
              <thead>
              <th v-for="item in tableHeading" :key="item.index">{{ item }}</th>
              </thead>
              <tbody>
              <tr v-for="(item, index) in data" :key="index">
                <td>{{ ++index }}</td>
                <td>{{ item.name }}</td>
                <td><a :href=" item ? item.image_path : ''" target="_blank"
                       class="text-white btn btn-primary rounded-pill">View File
                </a></td>
                <td class="action">
                  <button
                      title="Edit"
                      class="btn btn-sm btn-gray"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasRight"
                      aria-controls="offcanvasRight"
                      @click="editItem(item.id, index)"
                  >
                    <i class="ic-edit"></i>
                  </button>
                  <button
                      title="Delete"
                      class="btn btn-sm btn-gray"
                      @click="deleteItem(item.id, index)"
                  >
                    <i class="ic-delete"></i>
                  </button>
                </td>
              </tr>
              <template v-if="isLoading">
                <Loading/>
              </template>
              </tbody>
            </table>
            <div class="table-footer">
              <paginate
                  :page-class="'page-item'"
                  :page-count="page"
                  :page-link-class="'page-link'"
                  :container-class="'pagination  pagination-sm'"
                  :prev-text="`<i class='ic-chevron-left'></i>`"
                  :next-text="` <i class='ic-chevron-right'></i>`"
                  :prev-class="'page-link pagination-arrow mr-2'"
                  :next-class="'page-link pagination-arrow ml-2'"
                  :click-handler="handlePagination"
              ></paginate>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>


<script>
import VueErrors from '@/helper/errors'
import Loading from "../../components/Loading";
import Paginate from "vuejs-paginate";
import CKEditor from "ckeditor4-vue";
// import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ApiResource from "../../plugins/api";

let apiResource = new ApiResource("sitepages");

export default {
  name: "Sitepage",
  components: {VueErrors, Loading, Paginate, ckeditor: CKEditor.component,},
  data() {
    return {
      name: '',
      subtitle: '',
      link: null,
      ios_url: null,
      android_url: null,
      designation: null,
      summary: null,
      description: '',
      listing_items: [{listing: '',},],
      order: '',
      status: 1,
      type: '',
      image: null,
      edit_id: null,
      isLoading: false,
      errors: [],
      tableHeading: ['SN.', 'Name', 'File', 'Action'],
      data: [],
      page: 0,
      endPoint: 'sitepages',
      editor: ClassicEditor,
      paramQuery: {
        'page': 0
      },
      editorConfig: {
        // The configuration of the editor.
        height: "150px",
        allowedContent: true,
        extraAllowedContent: "iframe[*]",
      },
    };
  },
  methods: {
    addButtonEvent() {
      this.formReset();
    },
    addListing() {
      this.listing_items.push({listing: "",});
    },
    removeListing(index) {
      if (index > 0) {
        this.listing_items.splice(index, 1);
      } else {
        this.$toast.error('Not Allowed to Remove Last Field');
      }
    },
    async fetchData() {
      this.data = [];
      this.isLoading = true
      try {
        let {data} = await apiResource.list();
        this.data = data.sitepages.data;

        let total = data.sitepages.total;
        let per_page = data.sitepages.per_page;
        this.page = Math.ceil(total / per_page);
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    async handlePagination(pageNum) {
      try {
        this.isLoading = true
        this.paramQuery.page = pageNum;
        let {data} = await apiResource.list(this.paramQuery);
        this.data = data.sitepages.data;
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    storeData(storeUpdateFormData) {
      apiResource.store(storeUpdateFormData)
          .then((response) => {
            this.$toast.success(response.message);
            window.location.reload()
          })
          .catch((error) => {
            this.$toast.error("Please Check the Form. " + error.response.data.message);
            this.errors = error.response.data.errors;
          });
    },
    updateData(storeUpdateFormData) {
      storeUpdateFormData.append('_method', 'put');
      apiResource.update(this.edit_id, storeUpdateFormData)
          .then((response) => {
            this.$toast.success(response.message);
            window.location.reload()
          })
          .catch((error) => {
            this.$toast.error("Please Check the Form. " + error.response.data.message);
            this.errors = error.response.data.errors;
          });
    },
    deleteItem(id, index) {
      if (confirm("Are you sure you want to Delete?")) {
        apiResource.destroy(id)
            .then((response) => {
              this.$toast.success(response.message);
              this.fetchData();
              this.data.splice(index, 1)
            })
            .catch((error) => {
              console.log(error)
            });
      }
    },
    saveOrUpdateData() {
      let storeUpdateFormData = this.prepareFormData();
      let config = {
        header: {
          'Content-Type': 'multipart/form-data'
        }
      }
      if (this.edit_id > 0) {
        this.updateData(storeUpdateFormData, config);
      } else {
        this.storeData(storeUpdateFormData, config)
      }
    },


    prepareFormData() {
      let form_data = new FormData();
      form_data.append('name', this.name);
      form_data.append('subtitle', this.subtitle);
      form_data.append('summary', this.summary);
      form_data.append('description', this.description);
      form_data.append('link', this.link);
      form_data.append('android_url', this.android_url);
      form_data.append('ios_url', this.ios_url);
      form_data.append('status', this.status);
      form_data.append('order', this.order);
      form_data.append('image', this.image);
      form_data.append('listing_items', JSON.stringify(this.listing_items));
      return form_data;
    },
    editItem(id) {
      let data = this.data.find(data => data.id == id)
      if (data) {
        this.name = data.name
        this.subtitle = data.subtitle
        this.summary = data.summary
        this.description = data.description
        this.link = data.link
        this.ios_url = data.ios_url
        this.android_url = data.android_url
        this.order = data.order
        // this.status = data.status
        this.image = data.image
        this.listing_items = JSON.parse(data.listing_items)
        this.edit_id = data.id
      }
    },
    changeImage(event) {
      this.image = event.target.files[0]
    },
    formReset() {
      this.name = '',
          this.link = '',
          this.summary = '',
          this.subtitle = '',
          this.description = '',
          this.android_url = '',
          this.ios_url = '',
          // this.status = '',
          this.order = '',
          this.type = '',
          this.image = '',
          this.listing_items = [{listing: '',},]
      this.edit_id = null
    },
  },
  mounted() {
    this.fetchData();
  }
};
</script>