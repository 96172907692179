import request from './axios';

/**
 * Simple RESTful resource class
 */
class Resource {
    constructor(uri) {
        this.uri = uri;
    }

    list(query = '') {
        return request({
            url: '/' + this.uri,
            method: 'get',
            params: query,
        });
    }

    get(id) {
        return request({
            url: '/' + this.uri + '/' + id,
            method: 'get',
        });
    }

    store(resource) {
        return request({
            url: '/' + this.uri,
            method: 'post',
            data: resource,
        });
    }

    update(id, resource) {
        console.log("here", this.url);
        return request({
            url: '/' + this.uri + '/' + id,
            method: 'post',
            data: resource,
        });
    }

    destroy(id) {
        return request({
            url: '/' + this.uri + '/' + id,
            method: 'delete',
        });
    }

    exportFile(exportRoute){
        return request({
            url: '/' + this.uri + '/' + exportRoute,
            method: 'GET',
            responseType: 'blob',
        });
    }
}

export {Resource as default};