import Vue from "vue";
import App from "./App.vue";
import "./assets/icon.css";
Vue.config.productionTip = false;
Vue.config.silent;
import router from "./router";
import { store } from "./store";
import PortalVue from "portal-vue";

Vue.use(PortalVue);
import Toast from "vue-toastification";
import Multiselect from "vue-multiselect";
Vue.component("multiselect", Multiselect);
import "vue-multiselect/dist/vue-multiselect.min.css";
import "vue-toastification/dist/index.css";
Vue.use(Toast);
Vue.use(Toast);
// router.beforeEach((to, from, next) => {
//   console.log(to, from);
//   if (to.name !== "home" || to.name !== "contact") {
//     let body = document.querySelector("body");
//     body.classList.add("admin");
//   }
//   next();
// });

new Vue({
  mode: "history",
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
