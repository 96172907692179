<template>
  <div class="app-flex-layout">
    <div class="page-header">
      <h5>Post Management (News, Notices & Events)</h5>

      <button class="btn btn-sm btn-outline-primary" title="view" data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" @click="addButtonEvent">
        Add New Post
      </button>
    </div>

    <!--    OffCanvas Starts-->
    <div class="offcanvas offcanvas-end offcanvas-01" id="offcanvasRight" tabindex="-1"
      aria-labelledby="offcanvasRightLabel">
      <div class="offcanvas-header">
        <h5 class="text-dark" id="offcanvasRightLabel">{{ this.edit_id ? 'Edit Post' : 'Add New Post' }}</h5>
        <button class="btn btn-danger btn-sm" type="button" data-bs-dismiss="offcanvas" aria-label="Close">
          <i class="ic-close"></i>
        </button>
      </div>
      <div class="offcanvas-body">

        <form class="row mt-24" method="POST" @submit.prevent="saveOrUpdateData">

          <div class="col-md-16 mb-16">
            <label for="">Post Type</label>
            <select v-model="type" id="" class="form-select">
              <option value="">Select Type..</option>
              <option value="news">News</option>
              <option value="notice">Notice</option>
              <option value="event">Events</option>
            </select>
            <VueErrors :errors=this.errors error_name="type" />
          </div>

          <div class="col-md-12 mb-16">
            <label>Name</label>
            <input type="text" v-model="name" class="form-control" placeholder="Name" />
            <VueErrors :errors=this.errors error_name="name" />
          </div>

          <div class="col-md-12 mb-16">
            <label for="">Summary</label>
            <textarea v-model="summary" class="form-control" placeholder="Summary Here .."></textarea>
            <VueErrors :errors=this.errors error_name="summary" />
          </div>

          <div class="col-md-12 mb-16">
            <label for="">Description</label>
            <ckeditor :editor="editor" v-model="description" :config="editorConfig"></ckeditor>
            <VueErrors :errors=this.errors error_name="description" />
          </div>

          <div class="col-md-12 mb-16">
            <label>Order</label>
            <input type="number" v-model="order" class="form-control" placeholder="Order" />
            <VueErrors :errors=this.errors error_name="order" />
          </div>

          <div class="col-md-16 mb-16">
            <label for="">Show in Home</label>
            <select v-model="location" id="" class="form-select">
              <option value="1">Yes</option>
              <option value="0">No</option>
            </select>
            <VueErrors :errors=this.errors error_name="type" />
          </div>

          <div class="col-md-12 mb-16">
            <label>URL</label>
            <input type="text" v-model="link" class="form-control" placeholder="URL" />
            <VueErrors :errors=this.errors error_name="link" />
          </div>

          <div class="col-md-16 mb-16" v-if="link">
            <label>Link With</label>
            <select class="form-select" v-model="link_with">
              <option value=''>Choose Link With</option>
              <option value="1">Internal Link</option>
              <option value="2">External Link </option>
            </select>
            <VueErrors :errors=this.errors error_name="link_with" />
          </div>

          <div class="col-md-12 mb-16">
            <label for="">File/Image</label>
            <input type="file" class="form-control" @change="changeImage($event)" />
            <VueErrors :errors=this.errors error_name="image" />
          </div>

          <div class="col-md-12 mb-16" v-if="edit_id > 0">
            <img src="" :alt="this.image">
          </div>


          <div class="col-md-16 mb-16">
            <label for="">Status</label>
            <select v-model="status" class="form-select">
              <option value="">Select Status..</option>
              <option value="1">Active</option>
              <option value="0">Inactive</option>
            </select>
            <VueErrors :errors=this.errors error_name="status" />
          </div>

          <div class="offcanvas-footer">
            <button type="submit" class="btn btn-green mr-16">
              <i class="ic-checkmark mr-8"></i>
              {{ this.edit_id ? 'Update' : 'Save' }}
            </button>
            <button class="btn btn-red" data-bs-dismiss="offcanvas" aria-label="Close"><i class="ic-close mr-8"></i>
              Cancel
            </button>
          </div>

        </form>
      </div>

    </div>
    <!--    OffCanvas Ends-->


    <div class="pt-24 px-24">
    </div>
    <template>
      <div class="app-flex-layout p-24">
        <div class="d-flex mb-16">
          <div class="dropdown dropdown-01">
          </div>
        </div>
        <div class="position-relative h-100">
          <div class="app-height app-absolute-layout scrollable table-responsive">
            <table class="table table-01">
              <thead>
                <th v-for="item in tableHeading" :key="item.index">{{ item }}</th>
              </thead>
              <tbody>
                <tr v-for="(item, index) in data" :key="index">
                  <td>{{ ++index }}</td>
                  <td>
                    <div v-if="item.type == 'news'">News</div>
                    <div v-else-if="item.type == 'notice'">Notice</div>
                    <div v-else-if="item.type == 'event'">Event</div>
                  </td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.summary }}</td>
                  <td>
                    <img :src="item.image ? item.image_path : ''" width="100px" height="80px" :alt="item.name"
                      v-if="item.type == 'news' && item.image && item.image !== 'null'">
                    <a :href="item ? item.image_path : ''" target="_blank"
                      class="text-white btn btn-primary rounded-pill" v-if="item.type !== 'news'">
                      View File
                    </a>

                  </td>
                  <td>
                    <div v-if="item.status == 0" class="badge bg-red rounded-pill">Inactive</div>
                    <div v-else-if="item.status == 1" class="badge bg-green rounded-pill">Active</div>
                  </td>
                  <td>
                    <button title="Edit" class="btn btn-sm btn-gray" data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" @click="editItem(item.id, index)">
                      <i class="ic-edit"></i>
                    </button>
                    <button title="Delete" class="btn btn-sm btn-gray" @click="deleteItem(item.id, index)">
                      <i class="ic-delete"></i>
                    </button>
                  </td>
                </tr>
                <template v-if="isLoading">
                  <Loading />
                </template>
              </tbody>
            </table>
            <div class="table-footer">
              <paginate :page-class="'page-item'" :page-count="page" :page-link-class="'page-link'"
                :container-class="'pagination  pagination-sm'" :prev-text="`<i class='ic-chevron-left'></i>`"
                :next-text="` <i class='ic-chevron-right'></i>`" :prev-class="'page-link pagination-arrow mr-2'"
                :next-class="'page-link pagination-arrow ml-2'" :click-handler="handlePagination"></paginate>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>


<script>
import VueErrors from '@/helper/errors'
import Loading from "../../components/Loading";
import Paginate from "vuejs-paginate";
import CKEditor from "ckeditor4-vue";
// import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ApiResource from "@/plugins/api";
let apiResource = new ApiResource("posts");


export default {
  name: "Post",
  components: { VueErrors, Loading, Paginate, ckeditor: CKEditor.component, },
  data() {
    return {
      name: '',
      summary: null,
      description: '',
      order: '',
      status: '',
      type: '',
      location: 0,
      link: '',
      link_with: '',
      image: null,
      edit_id: null,
      isLoading: false,
      errors: [],
      tableHeading: ['SN.', 'Type', 'Name', 'Summary', 'Image', 'Status', 'Action'],
      data: [],
      page: 0,
      endPoint: 'posts',
      editor: ClassicEditor,
      editorConfig: {
        // The configuration of the editor.
        height: "150px",
        allowedContent: true,
        extraAllowedContent: "iframe[*]",
      },
      paramQuery: {
        'page': 0
      }
    };
  },
  methods: {
    addButtonEvent() {
      this.formReset();
    },
    async fetchData() {
      this.data = [];
      this.isLoading = true
      try {
        let { data } = await apiResource.list();
        this.data = data.posts.data;

        let total = data.posts.total;
        let per_page = data.posts.per_page;
        this.page = Math.ceil(total / per_page);
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    async handlePagination(pageNum) {
      try {
        this.isLoading = true
        this.paramQuery.page = pageNum;
        let { data } = await apiResource.list(this.paramQuery);
        this.data = data.posts.data;
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    storeData(storeUpdateFormData) {
      apiResource.store(storeUpdateFormData)
        .then((response) => {
          this.$toast.success(response.message);
          window.location.reload()
        })
        .catch((error) => {
          this.$toast.error("Please Check the Form. " + error.response.data.message);
          this.errors = error.response.data.errors;
        });
    },
    updateData(storeUpdateFormData) {
      storeUpdateFormData.append('_method', 'put');
      apiResource.update(this.edit_id, storeUpdateFormData)
        .then((response) => {
          this.$toast.success(response.message);
          window.location.reload()
        })
        .catch((error) => {
          this.$toast.error("Please Check the Form. " + error.response.data.message);
          this.errors = error.response.data.errors;
        });
    },
    deleteItem(id, index) {
      if (confirm("Are you sure you want to Delete?")) {
        apiResource.destroy(id)
          .then((response) => {
            this.$toast.success(response.message);
            this.fetchData();
            this.data.splice(index, 1)
          })
          .catch((error) => {
            console.log(error)
          });
      }
    },
    saveOrUpdateData() {
      let storeUpdateFormData = this.prepareFormData();
      if (this.edit_id > 0) {
        this.updateData(storeUpdateFormData);
      } else {
        this.storeData(storeUpdateFormData)
      }
    },

    prepareFormData() {
      let form_data = new FormData();
      form_data.append('name', this.name);
      form_data.append('summary', this.summary);
      form_data.append('description', this.description);
      form_data.append('status', this.status);
      form_data.append('type', this.type);
      form_data.append('order', this.order);
      form_data.append('location', this.location);
      form_data.append('image', this.image);
      form_data.append('link', this.link);
      form_data.append('link_with', this.link_with);

      return form_data;
    },
    editItem(id) {
      let data = this.data.find(data => data.id == id)
      if (data) {
        this.name = data.name
        this.type = data.type
        this.summary = data.summary
        this.description = data.description
        this.order = data.order
        this.location = data.location
        this.status = data.status
        this.image = data.image
        this.edit_id = data.id
        this.link = data.link
        this.link_with = data.link_with
      }
    },
    changeImage(event) {
      this.image = event.target.files[0]
    },
    formReset() {
      this.name = '',
        this.summary = '',
        this.status = '',
        this.order = '',
        this.location = 0,
        this.type = '',
        this.image = '',
        this.edit_id = null,
        this.link = '',
        this.link_with = ''
    },
  },
  mounted() {
    this.fetchData();
  }
};
</script>