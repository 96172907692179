<template>
  <div class="app-flex-layout">
    <div class="page-header">
      <h5>Contact Form Message</h5>
    </div>

    <div class="pt-24 px-24">
    </div>
    <template>
      <div class="app-flex-layout p-24">
        <div class="d-flex mb-16">
          <div class="dropdown dropdown-01">
          </div>
        </div>
        <div class="position-relative h-100">
          <div class="app-height app-absolute-layout scrollable table-responsive">
            <table class="table table-01">
              <thead>
              <th v-for="item in tableHeading" :key="item.index">{{ item }}</th>
              </thead>
              <tbody>

              <tr v-if="data.length === 0">No Data Found</tr>
              <tr v-for="(item, index) in data" :key="index">
                <td>{{ ++index }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.email }}</td>
                <td>{{ item.subject }}</td>
                <td>{{ item.message }}</td>
                <td class="action">
                  <button
                      title="Delete"
                      class="btn btn-sm btn-gray"
                      @click="deleteItem(item.id, index)"
                  >
                    <i class="ic-delete"></i>
                  </button>
                </td>
              </tr>
              <template v-if="isLoading">
                <Loading/>
              </template>
              </tbody>
            </table>
            <div class="table-footer">
              <paginate
                  :page-class="'page-item'"
                  :page-count="page"
                  :page-link-class="'page-link'"
                  :container-class="'pagination  pagination-sm'"
                  :prev-text="`<i class='ic-chevron-left'></i>`"
                  :next-text="` <i class='ic-chevron-right'></i>`"
                  :prev-class="'page-link pagination-arrow mr-2'"
                  :next-class="'page-link pagination-arrow ml-2'"
                  :click-handler="handlePagination"
              ></paginate>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>


<script>
import Loading from "../../components/Loading";
import Paginate from "vuejs-paginate";
import ApiResource from "../../plugins/api";

let apiResource = new ApiResource("contact-forms");

export default {
  name: "CareerListing",
  components: {Loading, Paginate},
  data() {
    return {
      name: '',
      level: '',
      published_date: '',
      expiry_date: '',
      description: null,
      qualification: null,
      salary_and_benefits: null,
      order: '',
      status: '',
      edit_id: null,
      isLoading: false,
      errors: [],
      tableHeading: ['SN.', 'Name', 'Email', 'Subject', 'Message', 'Action'],
      data: [],
      page: 0,
      endPoint: 'contact-forms',
      paramQuery: {
        'page': 0
      }
    };
  },
  methods: {
    async fetchData() {
      this.data = [];
      this.isLoading = true
      try {
        let {data} = await apiResource.list();
        this.data = data.contact_form_message.data;

        let total = data.data.data.contact_form_message.total;
        let per_page = data.data.data.contact_form_message.per_page;
        this.page = Math.ceil(total / per_page);
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    async handlePagination(pageNum) {
      this.isLoading = true
      try {
        this.paramQuery.page = pageNum;
        let {data} = await apiResource.list(this.paramQuery);
        this.data = data.contact_form_message.data;
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    deleteItem(id, index) {
      if (confirm("Are you sure you want to Delete?")) {
        apiResource.destroy(id)
            .then(response => {
              this.$toast.success(response.data.message);
              this.fetchData();
              this.data.splice(index, 1)
            })
            .catch((error) => {
              console.log(error)
            });
      }
    },
  },
  mounted() {
    this.fetchData();
  }
};
</script>
