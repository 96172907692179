import Vue from "vue";
import Router from "vue-router";
import VueRouter from "vue-router";
import Login from "../views/Login/Login.vue";
import Main from "../views/Dashboard/index.vue";
import MainDashboard from "../views/Dashboard/MainDashboard.vue";
import RequestDashboard from "../views/Dashboard/RequestDashboard.vue";
import Complaint from "../views/Complaints/Complaint.vue";
import MasterConfig from "../views/MasterConfig/index.vue";
import FormElement from "../views/FormElement/index.vue";
import axios from "axios";
import VueAxios from 'vue-axios'
import Auth from "@/Auth";
import PageNotFound from "../components/PageNotFound/PageNotFound.vue"
import Slider from "@/views/Slider/Slider";
import CmsDashboard from "@/views/CmsDashboard/CmsDashboard";
import Popup from "@/views/Popup/Popup";
import Post from "@/views/Post/Post";
import Bod from "@/views/Member/Bod";
import Management from "@/views/Member/Management";
import Supervisory from "@/views/Member/Supervisory";
import Faq from "@/views/Faq/Faq";
import Document from "@/views/Document/Document";
import Sitepage from "@/views/Sitepage/Sitepage";
import Properties from "@/views/Properties/Properties";
import Branch from "@/views/Branch/Branch";
import Footer from "@/views/Footer/Footer";
import CompanySetting from "@/views/CompanySetting/CompanySetting";
import SocialLink from "@/views/SocialLink/SocialLink";
import AudioVideo from "@/views/AudioVideo/AudioVideo";
import CareerListing from "@/views/CareerListing/CareerListing";
import ContactForm from "@/views/ContactForm/ContactForm";
import CareerApplication from "@/views/CareerApplication/CareerApplication";
import GalleryCategory from "@/views/Gallery Category/GalleryCategory";
import Gallery from "@/views/Gallery/Gallery";
import ServiceLevelOne from "@/views/Service/ServiceLevelOne";
import ServiceLevelTwo from "@/views/Service/ServiceLevelTwo";
import ServiceLevelThree from "@/views/Service/ServiceLevelThree";
import MenuLevelOne from "@/views/Menu/MenuLevelOne";
import MenuLevelTwo from "@/views/Menu/MenuLevelTwo";
import MenuLevelThree from "@/views/Menu/MenuLevelThree";
import LoanApplication from "@/views/LoanApplication/LoanApplication";
import LoanType from "@/views/LoanType/LoanType";
import SidebarIcon from "@/views/SidebarIcon/SidebarIcon";
import MembershipApplication from "@/views/MembershipApplication/MembershipApplication";

Vue.use(Router, VueRouter);
Vue.use(VueAxios, axios)
axios.defaults.baseURL = `${process.env.VUE_APP_BASE_URL}/api/`
const routes = [
    {
        path: "/", name: "Main", component: Main,
        meta: {requiresAuth: true},
        children: [
            {path: "/", name: "CmsDashboard", component: CmsDashboard,},
            {path: "/sliders", name: "Slider", component: Slider,},
            {path: "/popups", name: "Popup", component: Popup,},
            {path: "/posts", name: "Post", component: Post,},
            {path: "/bod", name: "Bod", component: Bod,},
            {path: "/management", name: "Management", component: Management,},
            {path: "/supervisory", name: "Supervisory", component: Supervisory,},
            {path: "/faqs", name: "Faq", component: Faq,},
            {path: "/documents", name: "Document", component: Document,},
            {path: "/site-pages", name: "Sitepage", component: Sitepage,},
            {path: "/branches", name: "Branch", component: Branch,},
            {path: "/properties", name: "Properties", component: Properties,},
            {path: "/footers", name: "Footer", component: Footer,},
            {path: "/company-settings", name: "CompanySetting", component: CompanySetting,},
            {path: "/social-links", name: "SocialLink", component: SocialLink,},
            {path: "/audio-videos", name: "AudioVideo", component: AudioVideo,},
            {path: "/career-listings", name: "CareerListing", component: CareerListing,},
            {path: "/contact-form-message", name: "ContactForm", component: ContactForm,},
            {path: "/career-applications", name: "CareerApplication", component: CareerApplication,},
            {path: "/gallery-categories", name: "GalleryCategory", component: GalleryCategory,},
            {path: "/galleries", name: "Gallery", component: Gallery,},
            {path: "/service-level-one", name: "ServiceLevelOne", component: ServiceLevelOne,},
            {path: "/service-level-two", name: "ServiceLevelTwo", component: ServiceLevelTwo,},
            {path: "/service-level-three", name: "ServiceLevelThree", component: ServiceLevelThree,},
            {path: "/menu-level-one", name: "MenuLevelOne", component: MenuLevelOne,},
            {path: "/menu-level-two", name: "MenuLevelTwo", component: MenuLevelTwo,},
            {path: "/menu-level-three", name: "MenuLevelThree", component: MenuLevelThree,},
            {path: "/loan-application", name: "LoanApplication", component: LoanApplication,},
            {path: "/loan-types", name: "LoanType", component: LoanType,},
            {path: "/sidebar-icons", name: "SidebarIcon", component: SidebarIcon,},
            {path: "/membership-applications", name: "MembershipApplication", component: MembershipApplication,},

            {path: "/main-dashboard", name: "MainDashboard", component: MainDashboard,},
            {path: "/requests", name: "RequestDashboard", component: RequestDashboard,},
            {path: "complaint", name: "Complaint", component: Complaint,},
            {path: "master-config", name: "MasterConfig", component: MasterConfig,},
            {path: "form-element", name: "FormElement", component: FormElement,},

        ],
    },
    {path: "/login", name: "Login", component: Login, meta: {is_auth: true}},

    {path: "*", component: PageNotFound,},
];


const router = new VueRouter({
    // mode: 'history',
    routes: routes,
    linkExactActiveClass: "active",
});

/*router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth))
        Auth.check() ? next() : router.push('/login');
    else if (to.matched.some(record => record.meta.is_auth))
        Auth.check() ? router.push('') : router.push('/login');
    else next();
});*/

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (Auth.check()) {
            next();
        } else {
            router.push('/login');
        }
    } else {
        next();
    }
});
export default router;
