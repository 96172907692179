<template>
  <flex-layout>
    <page-header heading="Requests" action="Filter" />
    <div class="pt-24 px-24">
      <div class="row gy-16">
        <div class="col-lg-6 request">
          <div class="card-request">
            <div class="card-header">
              <h6>Registration Requests</h6>
            </div>
            <div class="card-body">
              <div class="row g-0">
                <div class="col-md-6">
                  <div class="card-dashboard">
                    <div>
                      <p>Today's</p>
                      <div class="align-center mt-4">
                        <h4 class="mr-8">1256</h4>
                        <router-link to="/"
                          ><i class="ic-link-external text-secondary"></i
                        ></router-link>
                      </div>
                    </div>
                    <div class="icon blue">
                      <i class="ic-calendar-fill"></i>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card-dashboard">
                    <div>
                      <p>Accepted</p>
                      <div class="align-center mt-4">
                        <h4 class="mr-8">1256</h4>
                        <router-link to="/"
                          ><i class="ic-link-external text-secondary"></i
                        ></router-link>
                      </div>
                    </div>
                    <div class="icon green">
                      <i class="ic-check-circle"></i>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card-dashboard">
                    <div>
                      <p>Pending</p>
                      <div class="align-center mt-4">
                        <h4 class="mr-8">1256</h4>
                        <router-link to="/"
                          ><i class="ic-link-external text-secondary"></i
                        ></router-link>
                      </div>
                    </div>
                    <div class="icon yellow">
                      <i class="ic-hour-glass"></i>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card-dashboard">
                    <div>
                      <p>Rejected</p>
                      <div class="align-center mt-4">
                        <h4 class="mr-8">1256</h4>
                        <router-link to="/"
                          ><i class="ic-link-external text-secondary"></i
                        ></router-link>
                      </div>
                    </div>
                    <div class="icon red">
                      <i class="ic-close-circle"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 request">
          <div class="card-request">
            <div class="card-header">
              <h6>Registration Requests</h6>
            </div>
            <div class="card-body">
              <div class="row g-0">
                <div class="col-md-6">
                  <div class="card-dashboard">
                    <div>
                      <p>Today's</p>
                      <div class="align-center mt-4">
                        <h4 class="mr-8">1256</h4>
                        <router-link to="/"
                          ><i class="ic-link-external text-secondary"></i
                        ></router-link>
                      </div>
                    </div>
                    <div class="icon blue">
                      <i class="ic-calendar-fill"></i>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card-dashboard">
                    <div>
                      <p>Accepted</p>
                      <div class="align-center mt-4">
                        <h4 class="mr-8">1256</h4>
                        <router-link to="/"
                          ><i class="ic-link-external text-secondary"></i
                        ></router-link>
                      </div>
                    </div>
                    <div class="icon green">
                      <i class="ic-check-circle"></i>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card-dashboard">
                    <div>
                      <p>Pending</p>
                      <div class="align-center mt-4">
                        <h4 class="mr-8">1256</h4>
                        <router-link to="/"
                          ><i class="ic-link-external text-secondary"></i
                        ></router-link>
                      </div>
                    </div>
                    <div class="icon yellow">
                      <i class="ic-hour-glass"></i>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card-dashboard">
                    <div>
                      <p>Rejected</p>
                      <div class="align-center mt-4">
                        <h4 class="mr-8">1256</h4>
                        <router-link to="/"
                          ><i class="ic-link-external text-secondary"></i
                        ></router-link>
                      </div>
                    </div>
                    <div class="icon red">
                      <i class="ic-close-circle"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 request">
          <div class="card-request">
            <div class="card-header">
              <h6>Registration Requests</h6>
            </div>
            <div class="card-body">
              <div class="row g-0">
                <div class="col-md-6">
                  <div class="card-dashboard">
                    <div>
                      <p>Today's</p>
                      <div class="align-center mt-4">
                        <h4 class="mr-8">1256</h4>
                        <router-link to="/"
                          ><i class="ic-link-external text-secondary"></i
                        ></router-link>
                      </div>
                    </div>
                    <div class="icon blue">
                      <i class="ic-calendar-fill"></i>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card-dashboard">
                    <div>
                      <p>Accepted</p>
                      <div class="align-center mt-4">
                        <h4 class="mr-8">1256</h4>
                        <router-link to="/"
                          ><i class="ic-link-external text-secondary"></i
                        ></router-link>
                      </div>
                    </div>
                    <div class="icon green">
                      <i class="ic-check-circle"></i>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card-dashboard">
                    <div>
                      <p>Pending</p>
                      <div class="align-center mt-4">
                        <h4 class="mr-8">1256</h4>
                        <router-link to="/"
                          ><i class="ic-link-external text-secondary"></i
                        ></router-link>
                      </div>
                    </div>
                    <div class="icon yellow">
                      <i class="ic-hour-glass"></i>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card-dashboard">
                    <div>
                      <p>Rejected</p>
                      <div class="align-center mt-4">
                        <h4 class="mr-8">1256</h4>
                        <router-link to="/"
                          ><i class="ic-link-external text-secondary"></i
                        ></router-link>
                      </div>
                    </div>
                    <div class="icon red">
                      <i class="ic-close-circle"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 request">
          <div class="card-request">
            <div class="card-header">
              <h6>Registration Requests</h6>
            </div>
            <div class="card-body">
              <div class="row g-0">
                <div class="col-md-6">
                  <div class="card-dashboard">
                    <div>
                      <p>Today's</p>
                      <div class="align-center mt-4">
                        <h4 class="mr-8">1256</h4>
                        <router-link to="/"
                          ><i class="ic-link-external text-secondary"></i
                        ></router-link>
                      </div>
                    </div>
                    <div class="icon blue">
                      <i class="ic-calendar-fill"></i>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card-dashboard">
                    <div>
                      <p>Accepted</p>
                      <div class="align-center mt-4">
                        <h4 class="mr-8">1256</h4>
                        <router-link to="/"
                          ><i class="ic-link-external text-secondary"></i
                        ></router-link>
                      </div>
                    </div>
                    <div class="icon green">
                      <i class="ic-check-circle"></i>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card-dashboard">
                    <div>
                      <p>Pending</p>
                      <div class="align-center mt-4">
                        <h4 class="mr-8">1256</h4>
                        <router-link to="/"
                          ><i class="ic-link-external text-secondary"></i
                        ></router-link>
                      </div>
                    </div>
                    <div class="icon yellow">
                      <i class="ic-hour-glass"></i>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card-dashboard">
                    <div>
                      <p>Rejected</p>
                      <div class="align-center mt-4">
                        <h4 class="mr-8">1256</h4>
                        <router-link to="/"
                          ><i class="ic-link-external text-secondary"></i
                        ></router-link>
                      </div>
                    </div>
                    <div class="icon red">
                      <i class="ic-close-circle"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </flex-layout>
</template>

<script>
  import FlexLayout from "../../components/Layout/FlexLayout.vue";
  import PageHeader from "../../components/Utility/PageHeader.vue";
  export default {
    name: "Main",
    components: {
      PageHeader,
      FlexLayout,
    },
    data() {
      return {
        tableData: [
          {
            appliedDate: "10/13/2021",
            fullName: "Maddie Lindemann",
            department: "Marketing",
            email: "oivons0@t.co",
            phone: "942-780-9888",
            status: "Offline",
          },
          {
            appliedDate: "4/25/2022",
            fullName: "Laurie Cockrem",
            department: "Training",
            email: "hmcpherson1@booking.com",
            phone: "256-356-0918",
            status: "Offline",
          },
          {
            appliedDate: "10/12/2021",
            fullName: "Felita Mackison",
            department: "Human Resources",
            email: "aeldin2@reddit.com",
            phone: "227-409-7271",
            status: "Online",
          },
          {
            appliedDate: "9/14/2021",
            fullName: "Robin Goade",
            department: "Support",
            email: "gkenforth3@sun.com",
            phone: "290-694-0490",
            status: "Online",
          },
          {
            appliedDate: "1/4/2022",
            fullName: "Steven Clink",
            department: "Accounting",
            email: "ltoler4@pinterest.com",
            phone: "419-142-5176",
            status: "Deactivated",
          },
          {
            appliedDate: "1/18/2022",
            fullName: "Fraze Woodroofe",
            department: "Engineering",
            email: "ljulyan5@vkontakte.ru",
            phone: "937-959-9786",
            status: "Offline",
          },
          {
            appliedDate: "11/30/2021",
            fullName: "Fernande Lugg",
            department: "Product Management",
            email: "mfeltham6@google.cn",
            phone: "352-275-4954",
            status: "Deactivated",
          },
        ],
        tableHeading: [
          {
            heading: "Applied Date",
          },
          {
            heading: "Full Name",
          },
          {
            heading: "Department",
          },
          {
            heading: "Email Address",
          },
          {
            heading: "View",
          },
          {
            heading: "Status",
          },
        ],
      };
    },
    methods: {},
  };
</script>

<style scoped>
  .request:nth-child(even) .card-request .card-header {
    background: #38b290;
  }
</style>
