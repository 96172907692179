<template>
  <wrapper-layout>
    <Header  />
    <base-layout>
      <MainSidebar  />
      <!-- <flex-layout> -->
      <router-view> </router-view>
      <!-- </flex-layout> -->
    </base-layout>
  </wrapper-layout>
</template>

<script>
  import WrapperLayout from "../../components/Layout/WrapperLayout.vue";
  import BaseLayout from "../../components/Layout/BaseLayout.vue";
  import Header from "../../components/Header/Header.vue";
  import MainSidebar from "../../components/Sidebar/MainSidebar.vue";
  // import FlexLayout from "../../components/Layout/FlexLayout.vue";

  export default {
    name: "Main",
    components: {
      WrapperLayout,
      BaseLayout,
      Header,
      MainSidebar,
      // FlexLayout,
    },
    data() {
      return {

      };
    },
    methods: {
      toggleDarkMode(state) {
        this.darkMode = state;
      },
    },
  };
</script>
